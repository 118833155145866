.product-name-dp {
  font-size: 1.6666666667em;
  font-weight: 500; }
  @media screen and (max-width: 767px) {
    .product-name-dp {
      width: 100%;
      font-size: 1.25em; } }
  @media screen and (device-aspect-ratio: 2 / 3) {
    .product-name-dp {
      font-size: 1.25em; } }
  @media screen and (device-aspect-ratio: 40 / 71) {
    .product-name-dp {
      font-size: 1.25em; } }

.textgray {
  color: #858585; }
  @media screen and (max-width: 767px) {
    .textgray {
      display: inline-block;
      width: 100%;
      margin: 0; } }
  @media screen and (device-aspect-ratio: 2 / 3) {
    .textgray {
      width: 100%; } }
  @media screen and (device-aspect-ratio: 40 / 71) {
    .textgray {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .viewsproduct {
    display: none; } }

@media screen and (max-width: 767px) {
  .rating-productdetail {
    display: inline; } }

.usernm-dunia {
  text-transform: capitalize; }
  @media screen and (max-width: 767px) {
    .usernm-dunia {
      display: none; } }

.clearb {
  clear: both; }

@media screen and (max-width: 767px) {
  .centerdps {
    text-align: center; } }

@media screen and (max-width: 767px) {
  .clearbothmobile {
    clear: both; } }

@media screen and (min-width: 992px) {
  .clearfloatwidth {
    float: none !important;
    width: auto !important; } }

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .clearfloatwidth {
    float: none !important;
    width: auto !important; } }

.dropdown-toggle span {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px; }

.mataeye {
  position: relative; }
  .mataeye .toggle-password {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5px;
    margin-top: 12px; }

.fontlabel {
  font-size: 1.0833333333em; }

@media screen and (max-width: 767px) {
  .mobilepad {
    padding: 0 !important; } }

.avatar {
  max-width: 100%;
  max-height: 100% !important;
  height: 35px; }
  @media screen and (max-width: 767px) {
    .avatar {
      height: 35px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      margin-top: 10px; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .avatar {
      height: 33px; } }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .avatar {
      height: 35px; } }

.material-icons {
  position: absolute;
  bottom: 0px;
  right: 0;
  color: #036eaa;
  background: #f6f7f9;
  padding: 4.5px 10px;
  border-radius: 0px 5px 5px 0px;
  width: 45px; }
  @media screen and (max-width: 767px) {
    .material-icons {
      background: transparent;
      width: 14px;
      top: -46px; } }

.accounthead {
  color: white;
  transition: color .5s, background .5s;
  padding: 0px 7px 5px 25px; }
  .accounthead i {
    vertical-align: bottom;
    font-size: 21px; }
  .accounthead:hover {
    color: #abd6ff; }
  .accounthead:focus {
    background: #1575d6;
    color: white; }

.list-unstyled li {
  display: inline-block; }

.btn-outline-success {
  color: #333;
  border: 0;
  background: #f1f1f1;
  font-size: 13px; }
  .btn-outline-success:hover {
    background-color: #0358AA; }
  .btn-outline-success:active.focus {
    background-color: #0358AA; }
  .btn-outline-success:focus.focus {
    background-color: #0358AA; }

.tab-content {
  box-shadow: none;
  border-radius: 0; }

.img-app-fix {
  height: 0;
  padding-bottom: 56.5%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (max-width: 767px) {
    .img-app-fix {
      padding-bottom: 75.5%; } }

.product-dapp {
  margin-right: 10px;
  border: 1px solid #f1f1f1;
  box-shadow: none; }
  @media screen and (max-width: 767px) {
    .product-dapp {
      border-radius: none;
      box-shadow: none;
      border: none; } }

.card .card-block div > .card {
  padding: 10px 10px;
  margin-top: 10px; }
  @media screen and (max-width: 767px) {
    .card .card-block div > .card {
      padding: 0; } }

.card .card-block {
  padding: 0.75rem 1rem; }

.card .card-block div > .card .card-block {
  padding: 0; }

.text-success {
  color: #f70c5e !important;
  font-size: 25px; }

@media screen and (max-width: 767px) {
  .my-ratings {
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.6);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(1);
    -webkit-transform-origin: 0 0;
    transform: scale(1);
    transform-origin: 0 0;
    margin-top: 10px; } }

.img-resp {
  max-width: 100%;
  display: block;
  padding: 20px 15px 20px;
  background: white;
  border: 1px solid #ddd;
  width: 100%;
  height: 100%;
  max-height: 100%; }

.ads-newrelase {
  background: #f5f5f5;
  margin: 0;
  color: #616161;
  font-size: 1em;
  padding: 12px;
  border-top: 1px solid #cacaca;
  border-style: solid;
  border-right: 1px solid #cacaca;
  border-left: 1px solid #cacaca;
  border-bottom: 0; }

.nopadding {
  padding: 0 !important; }

@media screen and (max-width: 767px) {
  .margin-login-register {
    margin-top: -43px; } }

.nomargin {
  margin: 0 !important; }

.padleft {
  padding-left: 0 !important; }

body {
  position: relative;
  color: black; }

.download-topupgame a {
  display: block; }
  @media screen and (device-aspect-ratio: 2 / 3) {
    .download-topupgame a {
      font-size: 11px; } }
  @media screen and (device-aspect-ratio: 40 / 71) {
    .download-topupgame a {
      font-size: 11px; } }
  .download-topupgame a img {
    max-width: 100%;
    height: 20px; }
    @media screen and (max-width: 767px) {
      .download-topupgame a img {
        height: 15px; } }

.colorbutton-download {
  background: #7ed320;
  color: white;
  border: 1px solid #5da50f;
  padding: 0.8rem 1rem; }
  @media screen and (max-width: 767px) {
    .colorbutton-download {
      padding: 0.4rem 0.5rem;
      font-size: 13px; } }

.comingsoon-download {
  background: darkgray;
  color: white;
  border: 1px solid darkgray;
  padding: 0.8rem 1rem;
  text-align: center;
  font-size: 1rem; }
  .comingsoon-download:hover {
    background-color: darkgray;
    border-color: darkgray;
    color: white; }
  @media screen and (max-width: 767px) {
    .comingsoon-download {
      padding: 0.4rem 0.5rem;
      font-size: 13px; } }

.btn-topup-game {
  background: #0358AA;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  user-select: none;
  color: white;
  border-radius: 2px;
  margin-top: 15px;
  border: 1px solid darkblue; }
  @media screen and (max-width: 767px) {
    .btn-topup-game {
      padding: 0.4rem 0.5rem;
      font-size: 13px;
      margin-top: 8px; } }
  .btn-topup-game:hover {
    color: white;
    background: #044b73; }

.comingsoon-topup-game {
  background: darkgray;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  user-select: none;
  color: white;
  border-radius: 2px;
  margin-top: 15px;
  border: 1px solid darkgray; }
  @media screen and (max-width: 767px) {
    .comingsoon-topup-game {
      padding: 0.4rem 0.5rem;
      font-size: 13px;
      margin-top: 8px; } }
  .comingsoon-topup-game:hover {
    color: white;
    background: darkgray; }

.breadcrumb {
  margin: 0;
  background-color: transparent; }

#screenshots .item {
  background: transparent;
  padding: 0;
  margin: 5px 20px 0px 0px; }

#screenshots.owl-theme .owl-next {
  right: -27px;
  height: 252px;
  top: 44px;
  border-radius: 0; }
  @media screen and (max-width: 767px) {
    #screenshots.owl-theme .owl-next {
      height: 100px; } }
  #screenshots.owl-theme .owl-next:hover {
    background: transparent;
    border: 1px solid #efefef; }

#screenshots.owl-theme .owl-prev {
  left: -12px;
  height: 252px;
  top: 44px;
  border-radius: 0; }
  @media screen and (max-width: 767px) {
    #screenshots.owl-theme .owl-prev {
      height: 100px; } }
  #screenshots.owl-theme .owl-prev:hover {
    background: transparent;
    border: 1px solid #efefef; }

.owl-theme .owl-nav [class*="owl-"] {
  background: white;
  border: 1px solid #efefef; }

.detailscsho {
  width: auto !important;
  height: 250px; }
  @media screen and (max-width: 767px) {
    .detailscsho {
      height: 100px; } }

.screenshoot-back {
  background-size: cover;
  background-position: center top;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  height: 0;
  padding-bottom: 150%; }

.qrcode-dn {
  font-size: 12.5em;
  color: #0a314a; }

.h2-text-dn {
  font-size: 1em;
  font-weight: 500;
  color: #616161;
  display: inline; }

.h5-text-dn {
  font-size: 17px;
  color: #373737;
  font-weight: 500;
  line-height: 1.6; }
  @media screen and (max-width: 767px) {
    .h5-text-dn {
      margin-left: 0; } }

#wrapper-duniappp {
  background: #dadada; }

.wrapper-header-top {
  position: relative;
  margin: 0;
  background-color: #262626;
  color: white;
  height: auto;
  padding-top: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  @media screen and (max-width: 767px) {
    .wrapper-header-top {
      display: none; } }
  .wrapper-header-top .pull-left .freehotline {
    margin-top: 0.5rem; }
    .wrapper-header-top .pull-left .freehotline a:hover {
      color: #2cb3ff; }
    .wrapper-header-top .pull-left .freehotline a img {
      max-width: 100%;
      height: 20px; }
  .wrapper-header-top .menu-top ul {
    display: flex;
    flex-flow: row wrap; }
    .wrapper-header-top .menu-top ul li {
      margin-top: 0.5rem;
      margin-right: 40px;
      list-style: none; }
      .wrapper-header-top .menu-top ul li a {
        text-decoration: none; }
        .wrapper-header-top .menu-top ul li a:hover {
          color: #2cb3ff; }

.app {
  display: block; }

.skin-1 .main-panel > .header {
  background: #046eaa !important; }

.skin-1 .main-panel > .main-content {
  background: #dadada; }

.main-panel > .header-secondary .navbar-heading, .main-panel > .header .navbar-heading {
  margin-top: 0.5rem; }

@media screen and (max-width: 767px) {
  .main-panel > .main-content > .content-view {
    padding: 0; } }

@media screen and (max-width: 767px) {
  .nopadding-mobile {
    padding: 0 !important; } }

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .tabletviews {
    width: 100% !important; } }

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .tabletviews {
    width: 100% !important; } }

header {
  background: #0358AA;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 60px; }
  @media screen and (max-width: 767px) {
    header {
      height: 55px; } }
  header .navbar {
    border: 0;
    display: inline-block;
    width: 100%;
    padding: 0rem 1rem; }
    header .navbar .header-inner {
      margin-top: 12px; }

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .img-logor {
    display: none !important; } }

.header-top-section {
  font-size: 0;
  height: auto;
  margin: 0; }
  .header-top-section li {
    display: inline-block;
    vertical-align: middle; }
    @media screen and (max-width: 767px) {
      .header-top-section li {
        font-size: initial; } }
    .header-top-section li.logo-duniaplatform {
      width: 14%;
      padding-left: 40px; }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .header-top-section li.logo-duniaplatform {
          width: 20%;
          padding-left: 25px; } }
    .header-top-section li.search-duniaplatform {
      width: 35%; }
      @media only screen and (max-width: 767px) and (min-width: 546px) {
        .header-top-section li.search-duniaplatform .col-md-12 .navbar-search {
          display: none; } }
      @media screen and (max-width: 767px) {
        .header-top-section li.search-duniaplatform {
          width: 92%; } }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .header-top-section li.search-duniaplatform {
          width: 50%; } }
      @media only screen and (max-width: 1199px) and (min-width: 992px) {
        .header-top-section li.search-duniaplatform {
          width: 33%;
          margin-left: 15px; } }
      @media only screen and (max-width: 767px) and (min-width: 546px) {
        .header-top-section li.search-duniaplatform {
          width: 82%; } }
    .header-top-section li.paymentlg-duniaplatform {
      width: 28%;
      text-align: right; }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .header-top-section li.paymentlg-duniaplatform {
          display: none; } }
      @media only screen and (max-width: 767px) and (min-width: 500px) {
        .header-top-section li.paymentlg-duniaplatform {
          display: none; } }
      .header-top-section li.paymentlg-duniaplatform .menu-headDekstop {
        font-size: 0;
        margin: 0 auto;
        text-align: right; }
        .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .android-head {
          font-size: 16px;
          vertical-align: middle;
          display: inline-block;
          margin-right: 55px;
          transition: color .5s, background .5s; }
          @media only screen and (max-width: 1199px) and (min-width: 992px) {
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .android-head {
              margin-right: 36px; } }
          .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .android-head a {
            -webkit-transition: color .5s, background .5s;
            transition: color .5s, background .5s; }
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .android-head a span:before {
              content: "";
              background: url(../img/icon/android-icon.png) no-repeat;
              position: absolute;
              background-size: 100%;
              left: -27px;
              width: 20px;
              height: 23px;
              top: -2px; }
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .android-head a span:hover {
              color: #abd6ff; }
        .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .blog-head {
          font-size: 16px;
          vertical-align: middle;
          display: inline-block;
          margin-right: 55px; }
          @media only screen and (max-width: 1199px) and (min-width: 992px) {
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .blog-head {
              margin-right: 36px; } }
          .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .blog-head a {
            -webkit-transition: color .5s, background .5s;
            transition: color .5s, background .5s; }
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .blog-head a:hover {
              color: #abd6ff; }
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .blog-head a span:before {
              content: "";
              background: url(../img/icon/blog-icon.png) no-repeat;
              position: absolute;
              background-size: 100%;
              left: -30px;
              width: 24px;
              height: 24px;
              top: -1px; }
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .blog-head a span:hover {
              color: #abd6ff; }
        .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .paymenthead {
          font-size: 16px;
          vertical-align: middle;
          display: inline-block;
          margin-right: 10px; }
          @media only screen and (max-width: 1199px) and (min-width: 992px) {
            .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .paymenthead {
              margin-right: -12px; } }
          .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .paymenthead a span:before {
            content: "";
            background: url(../img/icon/payment-icon.png) no-repeat;
            position: absolute;
            background-size: 100%;
            left: -26px;
            width: 20px;
            height: 23px;
            top: 0px; }
          .header-top-section li.paymentlg-duniaplatform .menu-headDekstop .paymenthead a span:hover {
            color: #abd6ff; }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .header-top-section li.paymentlg-duniaplatform {
          width: 12%; } }
      @media only screen and (max-width: 1199px) and (min-width: 992px) {
        .header-top-section li.paymentlg-duniaplatform {
          width: 28%; } }
      .header-top-section li.paymentlg-duniaplatform a span {
        color: white;
        font-size: 14px;
        vertical-align: middle;
        font-weight: 600;
        position: relative;
        margin-left: 10px; }
      .header-top-section li.paymentlg-duniaplatform a img {
        max-width: 100%;
        height: 15.5px;
        vertical-align: middle; }
    .header-top-section li.loginregister-duniaplatform {
      width: 20%;
      float: right;
      color: white; }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .header-top-section li.loginregister-duniaplatform {
          width: 30%; } }
      @media only screen and (max-width: 1199px) and (min-width: 992px) {
        .header-top-section li.loginregister-duniaplatform {
          width: 23%; } }
      .header-top-section li.loginregister-duniaplatform .nav {
        width: 100%;
        height: auto; }

.logindekst {
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .logindekst {
      display: none; } }

.loginmobi {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .loginmobi {
      display: none; } }

.navbar-nav .nav-link {
  padding-top: 0.5rem !important; }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .navbar-nav .nav-link {
      padding-top: 0.45rem !important; } }

.drawer-nav {
  background-color: #0358AA !important; }

.drawer-hamburger {
  margin-left: 30px;
  margin-top: 0px; }
  @media screen and (max-width: 767px) {
    .drawer-hamburger {
      margin-left: 15px;
      margin-top: -3px; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .drawer-hamburger {
      margin-left: 25	px; } }

.drawer-open .drawer-hamburger-icon:after {
  width: 26px !important;
  top: -5px !important; }

.drawer-open .drawer-hamburger-icon:before {
  width: 26px !important;
  top: -6px !important; }

.drawer-hamburger-icon:after {
  width: 20px !important;
  top: 7px !important; }

.drawer-hamburger-icon:before {
  width: 32px !important;
  top: -7px !important; }

.drawer-hamburger-icon {
  width: 25px !important; }

.drawer-hamburger-icon, .drawer-hamburger-icon:after, .drawer-hamburger-icon:before {
  background-color: white !important;
  height: 2.50px !important; }

.drawer--left .drawer-hamburger, .drawer--left.drawer-open .drawer-nav, .drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
  outline: none; }

.drawer-open .drawer-hamburger-icon {
  background-color: transparent !important; }

.drawer--left.drawer-open .drawer-hamburger {
  left: -0.8rem !important;
  top: 0.25rem; }
  @media screen and (max-width: 767px) {
    .drawer--left.drawer-open .drawer-hamburger {
      top: 0.5rem; } }

.drawer-overlay {
  background-color: rgba(25, 24, 24, 0.45); }

@media screen and (max-width: 767px) {
  .drawer--left .drawer-nav {
    background-color: #0358AA; } }

.navbar-search {
  position: relative; }
  .navbar-search .form-control {
    background-color: white;
    border-radius: 5px;
    padding: .5rem 1.3rem;
    color: #0358AA;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border: 0; }
    .navbar-search .form-control::-webkit-input-placeholder {
      color: #ddd; }
    .navbar-search .form-control:-moz-placeholder {
      color: #ddd; }
    .navbar-search .form-control:-ms-input-placeholder {
      color: #ddd; }
    .navbar-search .form-control::-ms-input-placeholder {
      color: #ddd; }
    .navbar-search .form-control:placeholder-shown {
      color: #ddd; }

.onlymobilesearch {
  display: none; }
  @media screen and (max-width: 767px) {
    .onlymobilesearch {
      display: block; } }

.searchmb-da {
  position: absolute;
  right: 0;
  margin-top: 15px; }
  .searchmb-da i {
    color: white;
    font-size: 30px; }

.bigBanner {
  overflow-x: hidden;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease; }
  .bigBanner .bigBanner-cont {
    margin-right: 10px; }
    .bigBanner .bigBanner-cont img {
      max-width: 100%;
      height: 250px; }
      @media screen and (max-width: 767px) {
        .bigBanner .bigBanner-cont img {
          height: 155px;
          border: 8px solid #ffffff; } }
      @media screen and (min-width: 991px) {
        .bigBanner .bigBanner-cont img {
          height: 400px;
          border: 10px solid #ffffff; } }

.bigBanner.slick-initialized {
  visibility: visible;
  opacity: 1; }

.titleban {
  font-size: 12px;
  display: block;
  position: relative;
  padding: 10px;
  margin: 0px 0px -4px;
  width: 20%;
  text-align: center;
  color: #0358AA; }
  @media screen and (max-width: 767px) {
    .titleban {
      display: none; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .titleban {
      display: none; } }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .titleban {
      display: none; } }
  .titleban span {
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: -20px; }
    .titleban span:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      outline: 1px solid transparent;
      border-radius: 10px 10px 0 0;
      background: white;
      content: '';
      transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
      transform-origin: 0 0;
      backface-visibility: hidden; }

.user-login-dap {
  display: inline-block;
  margin: 50px 0px 0px 0px;
  position: relative;
  width: 100%;
  text-align: center; }
  .user-login-dap .user-login-dap-avatar {
    border-radius: 50%;
    display: inline-block;
    width: 120px;
    height: 120px;
    border: 3px solid white; }
    .user-login-dap .user-login-dap-avatar img {
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%; }

.user-login-text {
  text-align: center;
  margin-top: 20px;
  color: white; }
  .user-login-text span {
    font-size: 20px;
    display: block;
    font-weight: 500; }
  .user-login-text .promo-ham {
    margin-top: 10px; }
    .user-login-text .promo-ham small {
      font-size: 11px;
      font-weight: 200; }
      @media screen and (max-width: 767px) {
        .user-login-text .promo-ham small {
          font-size: 15px; } }

.hamburger-menu-ul {
  font-size: 0;
  width: 75%;
  height: auto;
  margin-left: 35px;
  margin-top: 30px;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .hamburger-menu-ul {
      margin-left: 50px; } }
  .hamburger-menu-ul li {
    font-size: initial;
    list-style: none;
    padding: 7px 0px;
    border-bottom: 1px solid rgba(238, 238, 238, 0.14); }
    .hamburger-menu-ul li:first-child {
      border-top: 1px solid rgba(238, 238, 238, 0.14); }
    .hamburger-menu-ul li a {
      color: white;
      margin-left: 15px;
      transition: color .5s, background .5s; }
      .hamburger-menu-ul li a .ico-menu {
        max-width: 100%;
        height: 14px;
        vertical-align: middle; }
      .hamburger-menu-ul li a span {
        font-size: 14px;
        vertical-align: middle;
        margin-left: 10px; }
      .hamburger-menu-ul li a:hover {
        color: #86c4ff; }

.bigBannercategory {
  overflow-x: hidden; }
  @media screen and (max-width: 767px) {
    .bigBannercategory {
      display: none; } }
  @media screen and (min-width: 992px) {
    .bigBannercategory {
      display: none; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .bigBannercategory {
      display: none; } }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .bigBannercategory {
      display: none; } }
  .bigBannercategory .card {
    background-color: transparent;
    margin-bottom: 0; }
    .bigBannercategory .card .card-block .tab-content {
      background-color: transparent;
      padding: 0.6rem; }
  .bigBannercategory #slideCategory .slick-track .slick-slide {
    margin-right: 10px;
    width: 115px !important; }
    .bigBannercategory #slideCategory .slick-track .slick-slide a {
      white-space: initial;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 600;
      min-height: 2rem;
      line-height: 1.6; }

.item-download-payment {
  font-size: 0;
  width: 100%;
  height: auto;
  padding: 0px 0px 0px 20px;
  margin: 0;
  margin-top: 12px; }
  @media screen and (max-width: 767px) {
    .item-download-payment {
      padding: 0px 0px 0px 5px; } }
  .item-download-payment li {
    font-size: initial;
    vertical-align: middle;
    display: inline-block; }
    .item-download-payment li img {
      max-width: 100%;
      height: 30px; }
      @media screen and (max-width: 767px) {
        .item-download-payment li img {
          height: 25px; } }
    .item-download-payment li.download-b {
      width: 50%;
      text-align: center;
      display: inline-block !important;
      overflow: visible; }
      @media screen and (max-width: 767px) {
        .item-download-payment li.download-b {
          width: 60%; } }
    .item-download-payment li.pay-b {
      width: 50%;
      text-align: center;
      display: inline-block !important;
      overflow: visible; }
    .item-download-payment li.download-ba {
      width: 50%;
      text-align: center; }
      @media screen and (max-width: 767px) {
        .item-download-payment li.download-ba {
          width: 60%; } }
    .item-download-payment li.pay-ba {
      width: 50%;
      text-align: center;
      display: inline-block; }

.screenshoot-wrapper {
  margin-top: 10px; }

#listGamescarousel {
  padding: 5px 10px 10px 20px;
  position: relative;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    #listGamescarousel .slick-track {
      width: 1000px !important; } }
  @media screen and (max-width: 767px) {
    #listGamescarousel .slick-track .slick-slide {
      width: 130px !important; } }
  #listGamescarousel .slick-track .slick-slide img {
    margin: 0 !important; }

#listGamescarousel .slick-arrow.slick-prev {
  left: auto;
  right: 20px;
  top: 18px;
  background: white;
  border-radius: 2px;
  border: 1px solid #ddd;
  width: 26px;
  height: 26px;
  margin-right: 58px; }
  @media screen and (max-width: 767px) {
    #listGamescarousel .slick-arrow.slick-prev {
      top: auto;
      margin-top: -7px; } }
  #listGamescarousel .slick-arrow.slick-prev:before {
    content: '\f053';
    cursor: pointer;
    font-family: 'FontAwesome';
    color: #676364;
    font-size: 15px; }

.allexplore {
  position: relative;
  display: inline-block;
  width: 100%; }
  .allexplore .all-text-dn {
    background: white;
    border: 1px solid #ddd;
    font-size: 12px;
    padding: 4px 10px;
    display: inline;
    color: #616161;
    line-height: 1.2;
    position: absolute;
    right: 0;
    margin-right: 115px;
    margin-top: 10px; }
    .allexplore .all-text-dn:hover {
      color: #616161; }

#listGamescarousel .slick-arrow.slick-next {
  right: 40px;
  top: 18px;
  background: white;
  border-radius: 2px;
  border: 1px solid #ddd;
  width: 26px;
  height: 26px; }
  @media screen and (max-width: 767px) {
    #listGamescarousel .slick-arrow.slick-next {
      top: 18px; } }
  #listGamescarousel .slick-arrow.slick-next:before {
    content: '\f054';
    cursor: pointer;
    font-family: 'FontAwesome';
    color: #676364;
    font-size: 15px; }

.img-listed-dp {
  display: block;
  margin: 15px 0px;
  width: 145px;
  height: 145px; }
  @media screen and (max-width: 767px) {
    .img-listed-dp {
      width: auto;
      height: auto;
      max-width: 100%; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .img-listed-dp {
      width: 130px;
      height: 130px; } }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .img-listed-dp {
      width: 145px;
      height: 145px; } }

.paddingcartblock {
  padding: 2rem 2rem 0 2rem !important; }
  @media screen and (max-width: 767px) {
    .paddingcartblock {
      display: inline-block;
      padding: 30px;
      width: 100%;
      height: auto;
      position: relative;
      margin-left: 25px;
      margin-top: 1.5rem;
      padding: 0rem !important; } }
  .paddingcartblock .app-image {
    display: block; }
    .paddingcartblock .app-image img {
      width: 100%;
      padding: 0;
      border-radius: 20px; }
      @media screen and (max-width: 767px) {
        .paddingcartblock .app-image img {
          width: 160px; } }
      @media only screen and (max-width: 413px) and (min-width: 320px) {
        .paddingcartblock .app-image img {
          width: 130px; } }
    @media screen and (max-width: 767px) {
      .paddingcartblock .app-image {
        padding: 0rem 0rem 0rem 0rem !important;
        width: 140px;
        height: auto; } }
    @media screen and (device-aspect-ratio: 2 / 3) {
      .paddingcartblock .app-image {
        width: 100px; } }
    @media screen and (device-aspect-ratio: 40 / 71) {
      .paddingcartblock .app-image {
        width: 100px; } }

@media screen and (max-width: 767px) {
  .paddingcarttextbutton {
    padding: 0 5px !important;
    margin-left: 30px; } }

@media only screen and (max-width: 360px) and (min-width: 320px) {
  .paddingcarttextbutton {
    margin-left: 30px; } }

@media only screen and (max-width: 413px) and (min-width: 360px) {
  .paddingcarttextbutton {
    margin-left: 5px; } }

.tab-detailduniapp {
  position: relative;
  width: 100%;
  margin: 2em auto;
  z-index: 0;
  display: inline-block;
  padding: 0 2rem 0.75rem; }
  @media only screen and (min-width: 768px) {
    .tab-detailduniapp {
      padding: 0 2rem; } }
  @media screen and (max-width: 767px) {
    .tab-detailduniapp {
      padding: 0.75rem 1rem;
      margin: 0em auto; } }
  .tab-detailduniapp:after {
    content: "";
    display: table;
    clear: both; }
    @media only screen and (min-width: 768px) {
      .tab-detailduniapp:after {
        display: none; } }
  .tab-detailduniapp::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 50px;
    z-index: 1;
    pointer-events: none;
    background: -webkit-linear-gradient(right, #f8f7ee, rgba(248, 247, 238, 0));
    background: linear-gradient(to left, #f8f7ee, rgba(248, 247, 238, 0));
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s; }
  .tab-detailduniapp nav {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      .tab-detailduniapp nav {
        position: relative;
        height: 100%;
        z-index: 1; } }
    @media only screen and (min-width: 960px) {
      .tab-detailduniapp nav {
        position: relative;
        float: none;
        background: transparent;
        box-shadow: none; } }

.tab-detailduniapp-navi {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent; }
  @media only screen and (min-width: 960px) {
    .tab-detailduniapp-navi {
      width: 100%;
      padding: 0;
      border-bottom: 1px solid #efefef; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .tab-detailduniapp-navi {
      width: 630px; } }
  .tab-detailduniapp-navi li {
    float: left;
    width: 25%;
    border: 1px solid #efefef;
    border-left: none; }
    @media only screen and (min-width: 767px) {
      .tab-detailduniapp-navi li {
        text-align: center;
        border-bottom: 0; } }
    .tab-detailduniapp-navi li:first-child {
      border-left: 1px solid #efefef; }
  .tab-detailduniapp-navi a {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-size: 0.75rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    color: #808080;
    padding-top: 10px; }
    @media only screen and (min-width: 960px) {
      .tab-detailduniapp-navi a {
        height: 60px;
        line-height: 60px;
        width: auto;
        text-align: left;
        font-size: 14px;
        font-size: 0.875rem;
        padding: 0 5em 0;
        text-align: center; } }
    @media only screen and (min-width: 767px) {
      .tab-detailduniapp-navi a::before {
        top: 22px; } }
    @media only screen and (min-width: 960px) {
      .tab-detailduniapp-navi a::before {
        top: 50%;
        margin-top: -10px;
        margin-left: 0;
        left: 38px; } }
  @media only screen and (min-width: 767px) {
    .tab-detailduniapp-navi a.selected {
      border-bottom: 2px solid #0358AA; } }
  @media only screen and (min-width: 960px) {
    .tab-detailduniapp-navi a.selected {
      border-bottom: 2px solid #0358AA; } }
  .tab-detailduniapp-navi:after {
    content: "";
    display: table;
    clear: both; }

.tab-detailduniapp-content {
  background: #ffffff;
  width: 100%;
  padding: 0;
  margin: 0;
  display: inline-block;
  height: auto !important; }
  @media only screen and (min-width: 767px) {
    .tab-detailduniapp-content {
      min-height: 480px; } }
  @media only screen and (min-width: 960px) {
    .tab-detailduniapp-content {
      min-height: 0; } }
  .tab-detailduniapp-content li {
    display: none;
    padding: 0; }
    @media only screen and (min-width: 767px) {
      .tab-detailduniapp-content li {
        padding: 0; } }
    @media only screen and (min-width: 960px) {
      .tab-detailduniapp-content li {
        padding: 0; } }
    .tab-detailduniapp-content li p {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.6;
      color: #565656;
      margin-bottom: 2em; }
      @media only screen and (min-width: 960px) {
        .tab-detailduniapp-content li p {
          font-size: 16px;
          font-size: 0.9rem; } }
  .tab-detailduniapp-content li.selected {
    display: inline-block;
    width: 100%;
    -webkit-animation: cd-fade-in 0.5s;
    -moz-animation: cd-fade-in 0.5s;
    animation: cd-fade-in 0.5s; }

.readMoreFade {
  bottom: -50px;
  display: none; }

.no-touch .tab-detailduniapp-navi a:hover {
  color: #29324e;
  background-color: rgba(233, 230, 202, 0.3); }

.tab-detailduniapp-navi a.selected {
  background-color: #ffffff !important;
  border-bottom: 2px solid #0358AA;
  color: #808080;
  width: 100%; }

.tab-detailduniapp-navi a::before {
  /* icons */
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -10px;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/vicons.svg");
  background-repeat: no-repeat; }

.tab-detailduniapp-navi a[data-content='details']::before {
  background-position: 0 0; }

.tab-detailduniapp-navi a[data-content='reviews']::before {
  background-position: -20px 0; }

.tab-detailduniapp-navi a[data-content='relateds']::before {
  background-position: -40px 0; }

.tab-detailduniapp-navi a[data-content='details'].selected::before {
  background-position: 0 -20px; }

.tab-detailduniapp-navi a[data-content='reviews'].selected::before {
  background-position: -20px -20px; }

.tab-detailduniapp-navi a[data-content='relateds'].selected::before {
  background-position: -40px -20px; }

.no-cssgradients .cd-tabs::after {
  display: none; }

.tab-detailduniapp.is-ended::after {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s; }

@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes cd-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#reviews {
  height: auto !important; }

.tabshome {
  position: relative;
  width: 100%;
  margin: 0;
  z-index: 0;
  display: inline-block;
  padding: 0.75rem 2rem; }
  @media only screen and (min-width: 768px) {
    .tabshome {
      padding: 0; } }
  @media only screen and (max-width: 768px) {
    .tabshome {
      padding: 0;
      margin-top: 15px; } }
  .tabshome:after {
    content: "";
    display: table;
    clear: both; }
    @media only screen and (min-width: 768px) {
      .tabshome:after {
        display: none; } }
  .tabshome::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 50px;
    z-index: 1;
    pointer-events: none;
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s; }
  .tabshome nav {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      .tabshome nav {
        position: relative;
        height: 100%;
        z-index: 1; } }
    @media only screen and (min-width: 960px) {
      .tabshome nav {
        position: relative;
        float: none;
        background: transparent;
        box-shadow: none; } }

.tabshome-navi {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent; }
  @media only screen and (min-width: 960px) {
    .tabshome-navi {
      width: 100%;
      padding: 0; } }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .tabshome-navi {
      width: 630px; } }
  .tabshome-navi li {
    float: left;
    width: 25%;
    border-left: none;
    margin-left: -8px; }
    .tabshome-navi li:first-child {
      margin-left: 20px; }
    @media only screen and (max-width: 767px) {
      .tabshome-navi li {
        text-align: center;
        width: 48%; } }
  .tabshome-navi a {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-size: 0.75rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    color: white;
    padding-top: 10px; }
    .tabshome-navi a .lts-tab:before {
      content: "";
      background: url(../img/icon/latest-off.png) no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 45px;
      top: 18px; }
      @media only screen and (max-width: 767px) {
        .tabshome-navi a .lts-tab:before {
          top: 12px;
          left: auto;
          margin-left: -25px; } }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .tabshome-navi a .lts-tab:before {
          left: 22px;
          top: 12px; } }
    .tabshome-navi a .exp-tab:before {
      content: "";
      background: url(../img/icon/world-off.png) no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 45px;
      top: 18px; }
      @media only screen and (max-width: 767px) {
        .tabshome-navi a .exp-tab:before {
          top: 12px;
          left: auto;
          margin-left: -25px; } }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .tabshome-navi a .exp-tab:before {
          left: 20px;
          top: 12px; } }
    @media only screen and (min-width: 960px) {
      .tabshome-navi a {
        height: 50px;
        line-height: 50px;
        text-align: left;
        font-size: 14px;
        font-size: 0.875rem;
        padding: 0;
        text-align: center;
        color: white; } }
    .tabshome-navi a::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      outline: 1px solid transparent;
      border-radius: 10px 10px 0 0;
      background: #0358AA;
      content: '';
      -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
      transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
    @media only screen and (min-width: 767px) {
      .tabshome-navi a::before {
        top: 22px; } }
    @media only screen and (min-width: 960px) {
      .tabshome-navi a::before {
        top: 50%;
        margin-top: -10px;
        margin-left: 0;
        left: 38px; } }
  .tabshome-navi:after {
    content: "";
    display: table;
    clear: both; }

.tabhome-content {
  background: #ffffff;
  width: 100%;
  padding: 0;
  margin-top: -3px;
  display: inline-block;
  height: 0; }
  .tabhome-content .product-dapp {
    border: 1px solid #f1f1f1;
    box-shadow: none;
    padding: 0px 5px;
    margin-right: 10px; }
    @media screen and (max-width: 767px) {
      .tabhome-content .product-dapp {
        border: 0; } }
    .tabhome-content .product-dapp .img-listed-dp {
      display: block;
      margin: 15px 0px;
      width: 100%;
      height: 100%; }
      .tabhome-content .product-dapp .img-listed-dp img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        display: block;
        padding: 5px;
        border-radius: 15px; }
    .tabhome-content .product-dapp .card-block {
      padding: 0.5rem 0.5rem 3rem 0.5rem;
      background: #fff;
      white-space: nowrap;
      overflow: hidden;
      width: 95%; }
      .tabhome-content .product-dapp .card-block .pull-left a {
        text-transform: uppercase; }
      .tabhome-content .product-dapp .card-block .title-game-d {
        color: #212121;
        font-weight: 600;
        margin-bottom: 1em; }
        @media screen and (max-width: 767px) {
          .tabhome-content .product-dapp .card-block .title-game-d {
            font-size: 12px; } }
      .tabhome-content .product-dapp .card-block .descriptiononlydekstop {
        font-size: 13px;
        color: #929292;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 40px;
        -moz-column-gap: 40px;
        column-gap: 40px; }
        @media screen and (max-width: 767px) {
          .tabhome-content .product-dapp .card-block .descriptiononlydekstop {
            display: none; } }
  @media only screen and (min-width: 767px) {
    .tabhome-content {
      min-height: 480px; } }
  @media only screen and (min-width: 960px) {
    .tabhome-content {
      min-height: 0; } }
  .tabhome-content li {
    display: block !important;
    height: 0px;
    overflow: hidden;
    border-radius: 5px; }
    @media only screen and (min-width: 767px) {
      .tabhome-content li {
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .tabhome-content li {
        border-radius: 0; } }
    @media only screen and (min-width: 960px) {
      .tabhome-content li {
        padding: 0; } }
    .tabhome-content li p {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.6;
      color: #565656;
      margin-bottom: 2em; }
      @media only screen and (min-width: 960px) {
        .tabhome-content li p {
          font-size: 16px;
          font-size: 0.9rem; } }
  .tabhome-content li.selected {
    display: inline-block;
    width: 100%;
    -webkit-animation: cd-fade-in 0.5s;
    -moz-animation: cd-fade-in 0.5s;
    animation: cd-fade-in 0.5s;
    height: auto;
    background: white; }

@media screen and (min-width: 992px) {
  .rowproduct:nth-child(6) {
    clear: both; } }

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .rowproduct:nth-child(6) {
    clear: both; } }

.no-touch .tabshome-navi a:hover {
  color: #29324e;
  background-color: rgba(233, 230, 202, 0.3); }

.tabshome-navi a.selected {
  width: 100%;
  color: #0358AA; }
  .tabshome-navi a.selected .lts-tab:before {
    content: "";
    background: url(../img/icon/latest-on.png) no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 45px;
    top: 18px; }
    @media only screen and (max-width: 767px) {
      .tabshome-navi a.selected .lts-tab:before {
        top: 12px;
        left: auto;
        margin-left: -25px; } }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      .tabshome-navi a.selected .lts-tab:before {
        left: 22px;
        top: 12px; } }
  .tabshome-navi a.selected .exp-tab:before {
    content: "";
    background: url(../img/icon/world-on.png) no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 45px;
    top: 18px; }
    @media only screen and (max-width: 767px) {
      .tabshome-navi a.selected .exp-tab:before {
        top: 12px;
        left: auto;
        margin-left: -25px; } }
  .tabshome-navi a.selected::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    outline: 1px solid transparent;
    border-radius: 10px 10px 0 0;
    background: white;
    content: '';
    -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
    transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.tabshome-navi a::before {
  /* icons */
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -10px;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/vicons.svg");
  background-repeat: no-repeat; }

.tabshome-navi a[data-content='details']::before {
  background-position: 0 0; }

.tabshome-navi a[data-content='reviews']::before {
  background-position: -20px 0; }

.tabshome-navi a[data-content='relateds']::before {
  background-position: -40px 0; }

.tabshome-navi a[data-content='details'].selected::before {
  background-position: 0 -20px; }

.tabshome-navi a[data-content='reviews'].selected::before {
  background-position: -20px -20px; }

.tabshome-navi a[data-content='relateds'].selected::before {
  background-position: -40px -20px; }

.screenshoot-img {
  padding: 0px; }

.description-product-dt {
  margin: 0;
  display: block; }
  .description-product-dt .description {
    color: #333; }

footer {
  background: #0c2132;
  color: #fff;
  margin: 0; }
  @media screen and (max-width: 767px) {
    footer {
      margin-top: -25px; } }

footer .centered-duniaapp {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }

footer .centered-duniaapp .footer-contact {
  width: 20%; }

footer .centered-duniaapp .footer-navigation {
  width: 60%; }

.footer-links-holder {
  padding: 35px 0px;
  list-style: none; }

footer .centered-duniaapp .footer-links-holder {
  width: 18.33333%;
  position: relative;
  float: left;
  margin: 0;
  padding: 35px 0px; }
  @media screen and (max-width: 767px) {
    footer .centered-duniaapp .footer-links-holder {
      width: 43.33333%;
      padding: 0; } }

footer .centered-duniaapp .footer-links-holder .footer-links {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-transition: max-height .5s;
  transition: max-height .5s;
  margin-top: 30px; }

footer .centered-duniaapp .footer-links-holder .footer-links li a {
  color: #9ca4ac;
  text-decoration: none;
  -webkit-transition: color .5s, background .5s;
  transition: color .5s, background .5s; }
  @media screen and (max-width: 767px) {
    footer .centered-duniaapp .footer-links-holder .footer-links li a {
      margin-right: 10px; } }

footer .centered-duniaapp .footer-links-holder .footer-links li a:hover {
  color: #fff;
  text-decoration: none; }

.footercopyright {
  color: #a7a7a7; }
  @media screen and (max-width: 767px) {
    .footercopyright {
      text-align: center; } }

/* Responsive Tablet Sizes */
@media only screen and (max-width: 749px) {
  footer .centered {
    flex-wrap: wrap; }
  footer .centered-duniaapp .footer-logo {
    max-width: 250px;
    width: 50%; }
  footer .centered-duniaapp .footer-contact {
    width: 50%;
    padding: 0 20px; }
  footer .centered-duniaapp .footer-navigation {
    width: 100%; }
  footer .centered-duniaapp .footer-navigation .footer-links-holder {
    padding: 0 20px; } }

@media only screen and (max-width: 500px) {
  footer .centered-duniaapp .footer-logo {
    width: 100%;
    margin: 0 auto;
    padding: 10px; }
  footer .centered-duniaapp .footer-contact {
    width: 100%; }
  footer .centered-duniaapp .footer-navigation .footer-links-holder {
    width: 100%;
    padding: 0; }
  footer .centered-duniaapp .footer-navigation .footer-links-holder h3 {
    margin: 0;
    padding: 10px 20px;
    border-top: #13a9ee 1px solid;
    cursor: pointer;
    /* Down Arrows */ }
  footer .centered-duniaapp .footer-navigation .footer-links-holder h3::after {
    float: right;
    margin-right: 10px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-top: 7px;
    width: 7px;
    height: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: transform .5s;
    transition: transform .5s; }
  footer .centered-duniaapp .footer-navigation .footer-links-holder .footer-links {
    max-height: 0;
    overflow: hidden; }
  footer .centered-duniaapp .footer-navigation .footer-links-holder.active h3::after {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  footer .centered-duniaapp .footer-navigation .footer-links-holder.active .footer-links {
    max-height: 10000px; } }

.loaderduniapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../img/loading.gif) 50% 50% no-repeat #f9f9f9;
  opacity: .8; }

.login-ov-card .card .card-header {
  padding: 20px 40px 10px; }
  @media screen and (max-width: 767px) {
    .login-ov-card .card .card-header {
      text-align: center; } }

.login-ov-card .card .card-block {
  padding: 0.75rem 2.5rem;
  margin-top: 20px; }

.login-register-ct {
  background: #0358AA;
  padding: 35px;
  height: 500px; }
  .login-register-ct .login-register-head {
    padding: 0.75rem 1rem;
    position: relative; }
    .login-register-ct .login-register-head h2 {
      color: white; }
  .login-register-ct .login-register-content {
    padding: 0.75rem 1rem; }
    .login-register-ct .login-register-content p {
      color: white; }
  .login-register-ct .login-register-cta {
    padding: 4.2rem 1rem 0; }

.register-login-ct {
  background: #0358AA;
  padding: 35px;
  height: 500px; }
  .register-login-ct .login-register-head {
    padding: 0.75rem 1rem;
    position: relative; }
    .register-login-ct .login-register-head h2 {
      color: white; }
  .register-login-ct .login-register-content {
    padding: 0.75rem 1rem; }
    .register-login-ct .login-register-content p {
      color: white; }
  .register-login-ct .login-register-cta {
    padding: 3rem 1rem 0; }

.socialmed.facebook {
  background: #3b5999;
  color: white !important;
  display: inline-block;
  margin-bottom: 0px;
  border-radius: 2px;
  margin-top: 8px;
  height: 40px;
  margin: auto;
  position: relative;
  padding: 0.5rem 4rem;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  text-indent: 10px;
  width: 300px;
  margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .socialmed.facebook {
      width: 100%;
      text-align: center;
      margin-right: 0; } }
  .socialmed.facebook:before {
    content: "\f230";
    font-size: 1.5em;
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    max-width: 100%;
    text-indent: 25px;
    top: 4px; }
    @media screen and (max-width: 767px) {
      .socialmed.facebook:before {
        left: 25px; } }
    @media screen and (device-aspect-ratio: 2 / 3) {
      .socialmed.facebook:before {
        left: 10px; } }
    @media screen and (device-aspect-ratio: 40 / 71) {
      .socialmed.facebook:before {
        left: 10px; } }

.socialmed.google {
  background: white;
  color: #333 !important;
  display: inline-block;
  margin-bottom: 0px;
  border-radius: 2px;
  margin-top: 8px;
  height: 40px;
  margin: auto;
  position: relative;
  padding: .5rem 4rem;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #e6e6e6;
  width: 300px; }
  @media screen and (max-width: 767px) {
    .socialmed.google {
      width: 100%;
      text-align: center; } }
  .socialmed.google:before {
    content: url(../img/googlelogo.png);
    position: absolute;
    left: 0;
    max-width: 100%;
    text-indent: 20px;
    top: 9px; }
    @media screen and (max-width: 767px) {
      .socialmed.google:before {
        left: 25px; } }
    @media screen and (device-aspect-ratio: 2 / 3) {
      .socialmed.google:before {
        left: 10px; } }
    @media screen and (device-aspect-ratio: 40 / 71) {
      .socialmed.google:before {
        left: 10px; } }

.regwithemail {
  font-size: 15px;
  color: #444;
  font-weight: 400;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .regwithemail {
      display: none; } }

.styling-btn {
  background: #0695e4;
  border-color: #0695e4;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: left;
  transition: color .5s, background .5s; }
  @media screen and (max-width: 767px) {
    .styling-btn {
      text-align: center; } }
  .styling-btn:after {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
    right: 0;
    margin-right: 15px; }
  .styling-btn:hover {
    background: #0776b3;
    border-color: #0695e4; }
  .styling-btn:active {
    background: #0776b3;
    border-color: #0695e4; }
  .styling-btn:focus {
    background: #0776b3;
    border-color: #0695e4; }

.styling-reg {
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  text-align: left;
  transition: color .5s, background .5s; }
  .styling-reg:after {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
    right: 0;
    margin-right: 15px; }
  .styling-reg:hover {
    background: white;
    border-color: #0695e4;
    color: #0776b3; }
  .styling-reg:active {
    background: #0776b3;
    border-color: #0695e4; }
  .styling-reg:focus {
    background: #0776b3;
    border-color: #0695e4; }

.sharetosocmed {
  font-size: 15px;
  color: #909090;
  font-weight: 500; }

.forgotpass {
  float: right;
  color: #3584ab;
  font-size: 11px;
  font-weight: 400; }

/* Typography */
footer h3 {
  margin-bottom: 0;
  font-size: 18px; }

footer p, footer li {
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 10px 0; }

#social {
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    #social {
      display: flex;
      align-items: center;
      justify-content: center; } }

.smGlobalBtn {
  /* global button class */
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 2px solid #ddd;
  /* add border to the buttons */
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  line-height: 2em;
  border-radius: 27px;
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px; }

.facebookBtn {
  text-indent: -66666em;
  background: url(../img/fb_icon.png) center no-repeat;
  display: inline-block;
  background-size: contain;
  width: 40px;
  height: 40px; }

.twitterBtn {
  text-indent: -66666em;
  background: url(../img/tw_icon.png) center no-repeat;
  display: inline-block;
  background-size: contain;
  width: 40px;
  height: 40px; }

.instagramBtn {
  text-indent: -66666em;
  background: url(../img/ig_icon.png) center no-repeat;
  display: inline-block;
  background-size: contain;
  width: 40px;
  height: 40px; }

/* Misc CSS */
.clearfix:before, .clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
  clear: both; }

@media screen and (max-width: 767px) {
  .profileSlide {
    display: none !important; } }

.profileSlide .profileSlide-img {
  background-size: cover;
  background-position: center top;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  height: 0;
  padding-bottom: 19%; }

.profil-section {
  position: relative; }
  @media screen and (max-width: 767px) {
    .profil-section {
      margin: 0;
      background: white;
      padding-top: 100px; } }
  .profil-section .profil {
    font-size: 0;
    width: 100%;
    height: auto;
    display: inline-block;
    background: white;
    padding: 10px;
    border: 0.5px solid rgba(167, 167, 167, 0.59); }
    @media screen and (max-width: 767px) {
      .profil-section .profil {
        border: 0; } }
    .profil-section .profil li {
      font-size: initial;
      display: inline-block;
      vertical-align: middle; }
      .profil-section .profil li.profil-name {
        width: auto;
        margin-right: 10px;
        margin-left: 225px;
        font-weight: 500; }
        @media screen and (max-width: 767px) {
          .profil-section .profil li.profil-name {
            width: 100%;
            text-align: center;
            margin-right: 0	px;
            margin-left: 0px;
            padding-top: 50px; } }
        @media only screen and (max-width: 991px) and (min-width: 768px) {
          .profil-section .profil li.profil-name {
            margin-left: 215px; } }
        .profil-section .profil li.profil-name h1 {
          font-size: 27px;
          color: #323232; }
      .profil-section .profil li.profil-socmed {
        width: 15%;
        margin-left: 10px; }
        @media screen and (max-width: 767px) {
          .profil-section .profil li.profil-socmed {
            width: 100%;
            margin-left: 0;
            text-align: center; } }
        @media only screen and (max-width: 991px) and (min-width: 768px) {
          .profil-section .profil li.profil-socmed {
            width: 18%; } }
        .profil-section .profil li.profil-socmed img {
          max-width: 100%;
          height: 24px; }
        .profil-section .profil li.profil-socmed a {
          margin-right: 10px; }
      .profil-section .profil li.profil-menus {
        width: 40%; }
        @media screen and (max-width: 767px) {
          .profil-section .profil li.profil-menus {
            display: none; } }
        @media only screen and (max-width: 991px) and (min-width: 768px) {
          .profil-section .profil li.profil-menus {
            width: 85%; } }
        @media only screen and (max-width: 1199px) and (min-width: 992px) {
          .profil-section .profil li.profil-menus {
            width: 62%; } }
        .profil-section .profil li.profil-menus ul {
          text-align: right; }
          .profil-section .profil li.profil-menus ul li {
            margin-right: 27px; }
            .profil-section .profil li.profil-menus ul li a {
              color: #4f4f4f;
              font-size: 16px;
              font-weight: 500; }

.editprofile-head {
  padding: 15px 0px 15px 20px;
  border-bottom: 1px solid rgba(169, 169, 169, 0.54);
  background: #f5f5f5; }
  @media screen and (max-width: 767px) {
    .editprofile-head {
      border-bottom: 0;
      padding: 15px 0px 15px 36px; } }

.profil-photoupload {
  position: absolute;
  top: -110px;
  margin-left: 50px;
  border: 7px solid white;
  border-radius: 50%; }
  @media screen and (max-width: 767px) {
    .profil-photoupload {
      top: -35px;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 0; } }

.profil-pic-u {
  height: 150px;
  width: 150px;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  vertical-align: middle;
  text-align: center;
  color: transparent;
  transition: all .3s ease;
  text-decoration: none;
  border-radius: 50%; }
  .profil-pic-u span {
    display: inline-block;
    padding-top: 4.5em;
    padding-bottom: 4.5em; }

#imageUpload {
  display: none; }

.profil-content-cms {
  margin-top: 5px;
  position: relative;
  width: 100%;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .profil-content-cms {
      margin-top: 0; } }

.label-icon {
  border-radius: 7em;
  border: 1px solid #c1c1c1;
  padding: 0px 5px;
  width: 2em;
  height: 2em;
  display: inline-block; }
  .label-icon img {
    max-width: 100%;
    height: 15px; }

.wraptable-user .table tr {
  border: 1px solid rgba(208, 208, 208, 0.05);
  border-top: 0; }
  .wraptable-user .table tr td {
    border: 1px solid rgba(140, 140, 140, 0.29);
    border-top: 1px solid rgba(140, 140, 140, 0.29);
    padding: 5px 10px 5px 5px !important;
    vertical-align: middle; }
    @media screen and (max-width: 767px) {
      .wraptable-user .table tr td {
        display: block;
        border: 0;
        border-top: 0; } }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      .wraptable-user .table tr td {
        display: block;
        border: 0;
        border-top: 0; } }
    .wraptable-user .table tr td:nth-child(odd) {
      background: #f5f5f5; }
      @media screen and (max-width: 767px) {
        .wraptable-user .table tr td:nth-child(odd) {
          background: white; } }
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        .wraptable-user .table tr td:nth-child(odd) {
          background: white; } }

.wraptable-user .table th {
  border: 1px solid rgba(208, 208, 208, 0.05);
  border-top: 0; }

.btn-editprofile {
  background: #0358AA;
  border: 0; }
  .btn-editprofile:hover {
    background: #0358AA; }

.btn-requestdev {
  background: #0358AA;
  border: 0; }
  .btn-requestdev:hover {
    background: #0358AA; }

@media screen and (max-width: 767px) {
  .hidemobile-duniapp {
    display: none; } }

.showmobile-duniapp {
  display: none; }
  @media screen and (max-width: 767px) {
    .showmobile-duniapp {
      display: block; } }
  .showmobile-duniapp li {
    display: block; }

.nav .open > a {
  background-color: transparent; }

.nav .open > a:hover {
  background-color: transparent; }

.nav .open > a:focus {
  background-color: transparent; }

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

#wrapper.toggled {
  padding-left: 220px; }

#wrapper.toggled #sidebar-wrapper {
  width: 220px; }

#wrapper.toggled #page-content-wrapper {
  margin-right: -220px;
  position: absolute; }

#sidebar-wrapper {
  background: #1a1a1a;
  height: 100%;
  left: 220px;
  margin-left: -220px;
  overflow-x: hidden;
  overflow-y: auto;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 0;
  z-index: 1000; }

#sidebar-wrapper::-webkit-scrollbar {
  display: none; }

#page-content-wrapper {
  padding-top: 70px;
  width: 100%; }

.sidebar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 220px; }

.sidebar-nav li {
  display: inline-block;
  line-height: 20px;
  position: relative;
  width: 100%; }

.sidebar-nav li:before {
  background-color: #1c1c1c;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  -o-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 3px;
  z-index: -1; }

.sidebar-nav li:before {
  background-color: #6bb4e7; }

.sidebar-nav li:hover:before {
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  -o-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 100%; }

.sidebar-nav li a {
  color: #dddddd;
  display: block;
  padding: 10px 15px 10px 30px;
  text-decoration: none; }

.sidebar-nav li.open:hover before {
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  -o-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 100%; }

.sidebar-nav .dropdown-menu {
  background-color: #222222;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%; }

.sidebar-nav li a:hover, .sidebar-nav li a:active, .sidebar-nav li a:focus, .sidebar-nav li.open a:hover, .sidebar-nav li.open a:active, .sidebar-nav li.open a:focus {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none; }

.sidebar-nav > .sidebar-brand {
  font-size: 20px;
  height: 65px;
  line-height: 44px; }

.hamburger {
  background: transparent;
  border: none;
  display: block;
  height: 32px;
  margin-left: 15px;
  position: fixed;
  top: 20px;
  width: 32px;
  z-index: 999; }
  .hamburger:hover {
    outline: none; }
  .hamburger:focus {
    outline: none; }
  .hamburger:active {
    outline: none; }

.hamburger.is-closed:before {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  content: '';
  display: block;
  font-size: 14px;
  line-height: 32px;
  -ms-opacity: 0;
  opacity: 0;
  text-align: center;
  width: 100px; }

.hamburger.is-closed:hover .hamb-top {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  top: 0; }

.hamburger.is-closed:hover before {
  -webkit-transform: translate3d(-100px, 0, 0);
  -moz-transform: translate3d(-100px, 0, 0);
  -ms-transform: translate3d(-100px, 0, 0);
  -o-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: block;
  -ms-opacity: 1;
  opacity: 1; }

.hamburger.is-closed:hover .hamb-bottom {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  bottom: 0; }

.hamburger.is-closed .hamb-top {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  top: 5px; }

.hamburger.is-closed .hamb-middle {
  margin-top: -2px;
  top: 50%; }

.hamburger.is-closed .hamb-bottom {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  bottom: 5px; }

.hamburger.is-closed .hamb-top, .hamburger.is-closed .hamb-middle, .hamburger.is-closed .hamb-bottom, .hamburger.is-open .hamb-top, .hamburger.is-open .hamb-middle, .hamburger.is-open .hamb-bottom {
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%; }

.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  -moz-transition: -moz-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  -ms-transition: -ms-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  -o-transition: -o-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  margin-top: -2px;
  top: 50%; }

.hamburger.is-open .hamb-middle {
  display: none; }

.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  -moz-transition: -moz-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  -ms-transition: -ms-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  -o-transition: -o-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  margin-top: -2px;
  top: 50%; }

.hamburger.is-open:before {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  content: '';
  display: block;
  font-size: 14px;
  line-height: 32px;
  -ms-opacity: 0;
  opacity: 0;
  text-align: center;
  width: 100px; }

.hamburger.is-open:hover before {
  -webkit-transform: translate3d(-100px, 0, 0);
  -moz-transform: translate3d(-100px, 0, 0);
  -ms-transform: translate3d(-100px, 0, 0);
  -o-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: block;
  -ms-opacity: 1;
  opacity: 1; }

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1; }

.hamb-bottom, .hamb-middle, .hamb-top {
  background-color: black; }

.buttonlogin-ham {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 10px;
  width: 72.3%;
  margin-bottom: 10px;
  background: white;
  color: #0358AA;
  position: relative;
  border-radius: 2px;
  -webkit-transition: color .5s, background .5s;
  transition: color .5s, background .5s; }
  .buttonlogin-ham:before {
    content: "";
    background: url(../img/login-icon.png) no-repeat;
    position: absolute;
    width: 14px;
    height: 19px;
    left: auto;
    margin-left: -25px; }
  .buttonlogin-ham:hover {
    background: #e2f1ff;
    color: #0358AA; }
  .buttonlogin-ham img {
    max-width: 100%;
    height: 16px;
    vertical-align: top; }
  .buttonlogin-ham span {
    font-size: 15px;
    display: inline-block;
    font-weight: 600; }

.buttonregister-ham {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 10px;
  width: 72%;
  color: white;
  border-radius: 2px;
  -webkit-transition: color .5s, background .5s;
  transition: color .5s, background .5s; }
  .buttonregister-ham:before {
    content: "";
    background: url(../img/user-icon.png) no-repeat;
    position: absolute;
    width: 17px;
    height: 17px;
    left: auto;
    margin-left: -5px;
    margin-top: 2px; }
  .buttonregister-ham:hover {
    background: #14b3ff;
    color: white; }
  .buttonregister-ham img {
    max-width: 100%;
    height: 16px;
    vertical-align: top; }
  .buttonregister-ham span {
    font-size: 15px;
    display: inline-block;
    font-weight: 600;
    margin-left: 20px; }

[data-ratings] {
  display: inline; }

[data-ratings] svg {
  width: 16px;
  height: 16px;
  fill: #fdc305; }

.profile-timeline-content {
  margin-left: 90px; }
  .profile-timeline-content p {
    font-size: 12px;
    display: inline-block; }

.profile-timeline-header .profile-timeline-user img {
  max-width: 100%;
  height: 75px; }

.malefemale {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  font-size: 12px;
  vertical-align: bottom; }
  .malefemale i {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: inset 0 0 0 8px #fff;
    border: 1px solid gray;
    background: gray; }
  .malefemale:hover > i {
    box-shadow: inset 0 0 0 3px #fff;
    background: gray; }

.malefemale > input:checked + i {
  box-shadow: inset 0 0 0 3px #fff;
  background: #0358AA; }

.malefemale > input {
  visibility: hidden;
  position: absolute; }

.adsimg {
  max-width: 100%; }

.height-mb {
  height: 100%;
  min-height: 500px; }
  @media screen and (max-width: 767px) {
    .height-mb {
      min-height: 600px; } }

.heightreg-mb {
  height: 100%;
  min-height: 500px; }
  @media screen and (max-width: 767px) {
    .heightreg-mb {
      min-height: 840px; } }

.notmember-register {
  margin-top: 15px;
  display: inline-block; }
  @media screen and (min-width: 992px) {
    .notmember-register {
      display: none; } }
  .notmember-register span {
    display: block;
    font-size: 13px;
    color: #9e9e9e; }
  .notmember-register a {
    color: #05517b;
    font-weight: 500; }

.autocomplete-suggestions {
  border-radius: 5px; }
  .autocomplete-suggestions .autocomplete-suggestion {
    padding: .6em .7em;
    color: #0358AA;
    cursor: pointer; }
    .autocomplete-suggestions .autocomplete-suggestion span {
      margin-left: 20px;
      font-weight: 500;
      font-size: 1rem; }
    .autocomplete-suggestions .autocomplete-suggestion img {
      max-width: 100%;
      height: 80px;
      width: 80px; }

.relatehomeul {
  font-size: 0;
  width: 100%;
  height: auto; }
  .relatehomeul li {
    font-size: initial;
    display: inline !important;
    vertical-align: middle; }
    .relatehomeul li.displayimage-link {
      width: 22.5490196078%; }
    .relatehomeul li.displaytitle-desc {
      width: 34.7058823529%; }
    .relatehomeul li.displayimg-sl {
      width: 42.7450980392%; }

.gridlatestgame {
  padding: 15px; }
  .gridlatestgame .row {
    margin-bottom: 25px; }

.wrapper-itemlinktab {
  margin: 5px 0px;
  position: relative;
  padding: 10px 30px;
  display: inline-block;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .wrapper-itemlinktab {
      margin: 0;
      padding: 0 10px; } }
  .wrapper-itemlinktab:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 0.5em;
    border-bottom: 1px solid #b9b9b945;
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    bottom: -15px;
    display: block; }
  .wrapper-itemlinktab .left-i {
    float: left;
    width: 20%;
    text-align: center;
    padding: 0px 10px; }
    @media screen and (max-width: 767px) {
      .wrapper-itemlinktab .left-i {
        padding: 0;
        width: auto; } }
    .wrapper-itemlinktab .left-i .section-img-linkdetail {
      text-align: center;
      position: relative; }
      .wrapper-itemlinktab .left-i .section-img-linkdetail .section-img-detail img {
        display: block;
        width: 100%;
        height: auto;
        margin: auto;
        border-radius: 25px; }
        @media screen and (max-width: 767px) {
          .wrapper-itemlinktab .left-i .section-img-linkdetail .section-img-detail img {
            width: 96px;
            height: 96px; } }
  .wrapper-itemlinktab .middle-i {
    position: relative;
    width: 35%;
    float: left;
    overflow: hidden;
    padding: 0px 30px;
    height: 229px; }
    @media screen and (max-width: 767px) {
      .wrapper-itemlinktab .middle-i {
        float: none;
        width: auto;
        padding: 0;
        height: 120px;
        max-height: 260px;
        margin-left: 111px; } }
    .wrapper-itemlinktab .middle-i .game-title {
      font-size: 1rem;
      font-weight: 600;
      color: #212121;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      @media screen and (max-width: 767px) {
        .wrapper-itemlinktab .middle-i .game-title {
          white-space: normal; } }
      @media screen and (device-aspect-ratio: 2 / 3) {
        .wrapper-itemlinktab .middle-i .game-title {
          white-space: nowrap; } }
      @media screen and (device-aspect-ratio: 40 / 71) {
        .wrapper-itemlinktab .middle-i .game-title {
          white-space: nowrap; } }
    @media screen and (max-width: 767px) {
      .wrapper-itemlinktab .middle-i .my-ratings {
        zoom: 1;
        -moz-transform: scale(0.6);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.6);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.6);
        -webkit-transform-origin: 0 0;
        transform: scale(0.6);
        transform-origin: 0 0;
        margin-top: -10px; } }
    .wrapper-itemlinktab .middle-i small {
      display: block;
      margin: 0px 0px 15px; }
      .wrapper-itemlinktab .middle-i small a {
        color: #6fa57c;
        text-decoration: underline;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.8rem; }
        @media screen and (max-width: 767px) {
          .wrapper-itemlinktab .middle-i small a {
            font-size: .6rem; } }
    .wrapper-itemlinktab .middle-i .descriptiononlydekstop {
      white-space: normal;
      margin: 0px 0px 15px;
      color: #929292;
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      white-space: normal;
      -webkit-column-gap: 40px;
      -moz-column-gap: 40px;
      column-gap: 40px;
      height: 60px; }
      @media screen and (max-width: 767px) {
        .wrapper-itemlinktab .middle-i .descriptiononlydekstop {
          display: none; } }
    .wrapper-itemlinktab .middle-i .parent-cat {
      color: #0358AA;
      font-weight: 500;
      font-size: 1rem;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 15px;
      text-transform: capitalize; }
      @media screen and (max-width: 767px) {
        .wrapper-itemlinktab .middle-i .parent-cat {
          display: none; } }
    .wrapper-itemlinktab .middle-i .category-child {
      position: relative;
      margin-top: 20px;
      width: calc(100% + 50px); }
      @media screen and (max-width: 767px) {
        .wrapper-itemlinktab .middle-i .category-child {
          bottom: 14px;
          left: 0;
          margin-top: 0; } }
      .wrapper-itemlinktab .middle-i .category-child a {
        padding: 0 5px 0px 0px; }
        .wrapper-itemlinktab .middle-i .category-child a .cat-title {
          font-size: 11px;
          color: #0358AA;
          padding: 5px;
          border: 1px solid #5897d4; }
          @media screen and (max-width: 767px) {
            .wrapper-itemlinktab .middle-i .category-child a .cat-title {
              padding: 2px; } }
  .wrapper-itemlinktab .right-i {
    float: left;
    width: 45%;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .wrapper-itemlinktab .right-i {
        display: none; } }
    .wrapper-itemlinktab .right-i .screenshoot-back {
      background-size: cover;
      background-position: center top;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      height: 220px;
      width: 110px; }

.detail-btn {
  background: #0358AA;
  color: white;
  width: 100%;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0px;
  transition: color .5s, background .5s;
  margin-top: 33px; }
  .detail-btn:hover {
    background: #0e3e6d;
    color: white; }

#modalFacebook.modal {
  background: white;
  width: 50%;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%; }

.category-sidebar {
  display: inline; }
  .category-sidebar .list-unstyled {
    padding: 5px 5px 0px 10px;
    margin: 0; }
    .category-sidebar .list-unstyled li {
      display: inline-block; }
      .category-sidebar .list-unstyled li a {
        font-size: 12px;
        min-height: 0; }

.comsoon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right; }
  .comsoon span {
    font-size: 11px;
    font-weight: 500;
    color: #FFF;
    text-transform: capitalize;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #fe181b;
    background: linear-gradient(#fe4d67 0%, #fe181b 100%);
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    top: 19px;
    left: -21px; }
    .comsoon span::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #fe181b;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #fe181b; }
    .comsoon span::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #fe181b;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #fe181b; }

.comsoondetail {
  position: absolute;
  left: 7px;
  top: -4px;
  z-index: 1;
  overflow: hidden;
  width: 120px;
  height: 120px;
  text-align: right; }
  .comsoondetail span {
    font-size: 14px;
    font-weight: 500;
    color: #FFF;
    text-transform: capitalize;
    text-align: center;
    line-height: 25px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 165px;
    display: block;
    background: #fe181b;
    background: linear-gradient(#fe4d67 0%, #fe181b 100%);
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    top: 37px;
    left: -33px; }
    .comsoondetail span::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #fe181b;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #fe181b; }
    .comsoondetail span::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #fe181b;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #fe181b; }

.holder .jp-previous {
  border: 0 !important; }
  .holder .jp-previous:before {
    content: '\f053';
    cursor: pointer;
    font-family: 'FontAwesome';
    color: #ddd;
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    font-weight: 200; }

.holder .jp-previous.jp-disabled:before {
  content: '\f053';
  cursor: pointer;
  font-family: 'FontAwesome';
  color: #ddd;
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  font-weight: 200; }

.holder .jp-next {
  border: 0 !important; }
  .holder .jp-next:before {
    content: '\f054';
    cursor: pointer;
    font-family: 'FontAwesome';
    color: #ddd;
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    font-weight: 200; }

.holder a:hover {
  background-color: transparent !important; }

.holder {
  margin: 25px 0 !important; }
  .holder a {
    border: 1px solid #ddd;
    padding: 4px 10px; }

.holder a.jp-current, a.jp-current:hover {
  color: white !important;
  background: #0358AA !important; }

.holders .jp-previous {
  border: 0 !important; }
  .holders .jp-previous:before {
    content: '\f053';
    cursor: pointer;
    font-family: 'FontAwesome';
    color: #ddd;
    font-size: 13px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    font-weight: 200; }

.holders .jp-previous.jp-disabled:before {
  content: '\f053';
  cursor: pointer;
  font-family: 'FontAwesome';
  color: #ddd;
  font-size: 13px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  font-weight: 200; }

.holders .jp-next {
  border: 0 !important; }
  .holders .jp-next:before {
    content: '\f054';
    cursor: pointer;
    font-family: 'FontAwesome';
    color: #ddd;
    font-size: 13px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    font-weight: 200; }

.holders a:hover {
  background-color: transparent !important; }

.holders a.jp-previous {
  margin-right: 0; }

.holders {
  margin: 25px 10px !important; }
  .holders a {
    border: 1px solid #ddd;
    padding: 4px 10px; }

.holders a.jp-current, a.jp-current:hover {
  color: white !important;
  background: #0358AA !important; }

.choose-file {
  width: 100%;
  height: auto;
  background-color: white;
  position: relative;
  border: 1px solid #e5e5e5; }
  .choose-file input[type='file'] {
    width: 100%;
    height: 25px;
    opacity: 0; }
  .choose-file #val {
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 25px;
    text-indent: 10px;
    pointer-events: none; }
  .choose-file #button {
    cursor: pointer;
    display: block;
    width: 90px;
    background-color: #0358AA;
    height: 25px;
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 11px;
    line-height: 25px;
    text-align: center;
    -webkit-transition: 500ms all;
    -moz-transition: 500ms all;
    transition: 500ms all; }
    .choose-file #button:hover {
      background-color: #02315f; }

.facebook-congrat {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 30%; }
  .facebook-congrat h2 {
    color: #0358AA;
    font-size: 40px;
    font-weight: 700; }
  .facebook-congrat i {
    font-size: 80px;
    color: #0358AA; }
  .facebook-congrat p {
    color: #909090; }

.google-congrat {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 30%; }
  .google-congrat h2 {
    color: #0358AA;
    font-size: 40px;
    font-weight: 700; }
  .google-congrat i {
    font-size: 80px;
    color: #333; }
  .google-congrat p {
    color: #909090; }

.jssocials-share-link {
  border-radius: 5px; }

#qrcode {
  margin-top: 10px; }
  #qrcode canvas {
    max-width: 100%;
    width: 100px;
    height: 100px;
    max-height: 100%; }

.lightbox {
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  position: fixed;
  display: none;
  z-index: 9999;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0; }

.lightbox-container {
  position: relative;
  max-width: 960px;
  margin: 7% auto;
  display: block;
  padding: 0 3%;
  height: auto;
  z-index: 10; }

@media screen and (max-width: 768px) {
  .lightbox-container {
    margin-top: 10%; } }

@media screen and (max-width: 414px) {
  .lightbox-container {
    margin-top: 13%; } }

.lightbox-content {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7); }

.lightbox-close {
  text-transform: uppercase;
  background: transparent;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  display: block;
  border: none;
  color: white;
  top: -22px;
  right: 3%; }

.video-container {
  padding-bottom: 56.25%;
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  height: 0; }

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

#playme {
  background: white;
  text-transform: uppercase;
  font-weight: 300;
  border: none;
  color: white;
  padding: 5px 15px;
  display: inline-block;
  font-size: 14px;
  margin: 0; }
  #playme:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\f01d";
    font-family: FontAwesome;
    font-size: 100px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 767px) {
      #playme:before {
        font-size: 50px; } }
  #playme:hover:before {
    color: #eee; }
  #playme img {
    width: 335px;
    height: auto; }
    @media screen and (max-width: 767px) {
      #playme img {
        width: 135px; } }

.changeu {
  color: white !important;
  background: #0358AA;
  padding: 0px 10px;
  text-transform: capitalize;
  top: 15px;
  right: 20px;
  border-radius: 20px;
  position: absolute; }
  .changeu:hover {
    text-decoration: none;
    color: white;
    background: #0D6FCE; }

#eyepwd {
  position: absolute;
  right: 18px;
  top: 33px;
  background: transparent;
  border: none;
  outline: none; }

#eyepwd img {
  width: 18px; }

#eyeconfirm {
  position: absolute;
  right: 18px;
  top: 33px;
  background: transparent;
  border: none;
  outline: none; }

#eyeconfirm img {
  width: 18px; }

#eyepwdp {
  position: absolute;
  right: 5px;
  top: 7px;
  background: transparent;
  border: none;
  outline: none; }

#eyepwdp img {
  width: 18px; }

#eyeconfirmp {
  position: absolute;
  right: 5px;
  top: 7px;
  background: transparent;
  border: none;
  outline: none; }

#eyeconfirmp img {
  width: 18px; }

.tbsa {
  zoom: 1;
  position: relative;
  height: auto; }

.tbsa:after,
.tbsa:before {
  content: "";
  display: table; }

.tbsa:after {
  clear: both; }

.tbsa h4 {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  padding: 15px 20px; }

.tbsa h4:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.2); }

@media screen and (min-width: 600px) {
  .tbsa h4 {
    position: relative;
    width: 33.333333333333336%;
    height: 20%;
    display: block; } }

.tbsa ul {
  zoom: 1;
  position: relative;
  height: auto;
  min-height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  display: none; }

.tbsa ul:after,
.tbsa ul:before {
  content: "";
  display: table; }

.tbsa ul:after {
  clear: both; }

.tbsa ul li {
  list-style: circle; }

@media screen and (min-width: 600px) {
  .tbsa ul {
    position: absolute;
    width: 66.66666666666667%;
    right: 0;
    top: 0;
    padding: 15px 30px; } }

.tbsa .active {
  cursor: default;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: none; }

@media screen and (min-width: 600px) {
  .tbsa .active {
    background: transparent;
    border-right: 1px solid transparent; } }

.tbsa .active + ul {
  display: block; }

.scrollToTop {
  width: 100px;
  height: 130px;
  padding: 10px;
  text-align: center;
  background: 0 0;
  font-weight: 700;
  color: #444;
  text-decoration: none;
  position: fixed;
  right: 0;
  bottom: 0;
  display: none; }
