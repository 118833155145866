// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$maincolor: #0358AA;
$textgray:#858585;
$btndownload: #7ed320;

//fontsize detected
$fontsizedefault: 12;
@function em($pixels, $ct: $fontsizedefault){
	@return #{$pixels/$ct}em;
}

// logo, button, text & etc not nested
.product-name-dp{
	font-size:em(20);
	font-weight:500;
	@media screen and (max-width:767px){
	    width: 100%;
	    font-size:em(15);
	}
	@media screen and (device-aspect-ratio: 2/3) {
		font-size:em(15);
	}
	@media screen and (device-aspect-ratio: 40/71) {
		font-size:em(15);
	}
}
.textgray{
	color:$textgray;
	@media screen and (max-width: 767px){
		display: inline-block;
		width: 100%;
		margin: 0;
	}
	@media screen and (device-aspect-ratio: 2/3) {
		width: 100%;
	}
	@media screen and (device-aspect-ratio: 40/71) {
		width: 100%;
	}
}
.viewsproduct{
	@media screen and (max-width: 767px){
		display: none;
	}
}
.rating-productdetail{
	@media screen and (max-width: 767px){
		display:inline;
	}
}
.usernm-dunia{
	text-transform:capitalize;
	@media screen and (max-width:767px){
		display: none;
	}
}
.clearb{
	clear:both;
}
.centerdps{
	@media screen and (max-width: 767px){
		text-align: center;
	}
}
.clearbothmobile{
	@media screen and (max-width:767px){
		clear:both;
	}
}
.clearfloatwidth{
	@media screen and (min-width:992px){
		float: none !important;
		width: auto !important;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px){
		float: none !important;
		width: auto !important;	
	}
}
.dropdown-toggle{
	span{
		font-size: 14px;
		font-weight: 600;	
		margin-left: 10px;
	}
}
.mataeye{
	position:relative;
	.toggle-password{
	    position: absolute;
	    top: 0;
	    right: 0;
        margin-right: 5px;
    	margin-top: 12px;
	}
}
.fontlabel{
	font-size:em(13);
}
.mobilepad{
	@media screen and (max-width:767px){
		padding: 0 !important;
	}
}
.avatar{
    max-width: 100%;
    max-height: 100% !important;
    height: 35px;
    @media screen and (max-width: 767px){
	    height: 35px;
    	position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    margin-top:10px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px){
	    height: 33px;
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px){
    	height: 35px;
    }
}

.material-icons{
    position: absolute;
    bottom: 0px;
    right: 0;
    color: #036eaa;
    background: #f6f7f9;
    padding: 4.5px 10px;
    border-radius: 0px 5px 5px 0px;
    width: 45px;
    @media screen and (max-width: 767px){
		background: transparent;
	    width: 14px;
    	top: -46px;
    }
}

.accounthead{
	color:white;
	transition: color .5s, background .5s;
	padding: 0px 7px 5px 25px;
	i{
		vertical-align: bottom;
    	font-size: 21px;
	}
	&:hover{
	    color: #abd6ff;
	}
	&:focus{
		background: #1575d6;
		color: white;
	}
}
.list-unstyled{
	li{
		display: inline-block;
	}
}
.btn-outline-success{
	color:#333;
	border:0;
	background:#f1f1f1;
	font-size:13px;
	&:hover{
		background-color: $maincolor;
	}
	&:active.focus{
		background-color: $maincolor;
	}
	&:focus.focus{
		background-color: $maincolor;
	}
}
.tab-content{
	box-shadow:none;
	border-radius:0;
}

.img-app-fix{
	height:0;
	padding-bottom:56.5%;
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
	@media screen and (max-width:767px){
		padding-bottom: 75.5%;
	}
}
.product-dapp{
	margin-right:10px;
    border: 1px solid #f1f1f1;
    box-shadow:none;
	@media screen and(max-width: 767px){
		border-radius:none;
		box-shadow: none;
		border:none;
	}
}
.card .card-block div > .card {
	padding: 10px 10px;
	margin-top:10px;
	@media screen and (max-width:767px){
		padding: 0;
	}
}
.card .card-block {
	padding: 0.75rem 1rem;
}
.card .card-block div > .card .card-block{
	padding: 0;
}
.text-success{
	color:#f70c5e !important;
	font-size:25px;
}


.my-ratings{
	@media screen and (max-width:767px){
		zoom: 1;
	    -moz-transform: scale(1);
	    -moz-transform-origin: 0 0;
	    -o-transform: scale(0.6);
	    -o-transform-origin: 0 0;
	    -webkit-transform: scale(1);
	    -webkit-transform-origin: 0 0;
	    transform: scale(1);
	    transform-origin: 0 0;
	    margin-top: 10px;
	}
}
.img-resp{
    max-width: 100%;
    display: block;
    padding: 20px 15px 20px;
    background: white;
    border: 1px solid #ddd;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.ads-newrelase{
    background: #f5f5f5;
    margin: 0;
    color: #616161;
    font-size: em(12);
    padding: 12px;
    border-top: 1px solid #cacaca;
    border-style: solid;
    border-right: 1px solid #cacaca;
    border-left: 1px solid #cacaca;
    border-bottom: 0;
}
.nopadding{
	padding:0 !important;
}
.margin-login-register{
	@media screen and (max-width: 767px){
		margin-top:-43px;
	}
}
.nomargin{
	margin:0 !important;
}
.padleft{
	padding-left:0 !important;
}
body 
{
	position: relative;
    color:black;
}
.download-topupgame{
	a{
		display:block;
		@media screen and (device-aspect-ratio: 2/3) {
			font-size:11px;
		}
		@media screen and (device-aspect-ratio: 40/71) {
			font-size:11px;
		}
		img{
			max-width: 100%;
			height: 20px;
			@media screen and (max-width: 767px){
				height: 15px;
			}
		}
	}
}
.colorbutton-download{
	background:$btndownload;
	color:white;
	border:1px solid #5da50f	;
	padding: 0.8rem 1rem;
	@media screen and (max-width: 767px){
		padding: 0.4rem 0.5rem;
		font-size: 13px;
	}
}
.comingsoon-download{
	background:darkgray;
	color:white;
	border:1px solid darkgray;
	padding: 0.8rem 1rem;
	text-align:center;
	font-size:1rem;
	&:hover{
	    background-color: darkgray;
    	border-color: darkgray;
    	color:white;
	}
	@media screen and (max-width: 767px){
		padding: 0.4rem 0.5rem;
		font-size: 13px;
	}
}
.btn-topup-game{
	background:$maincolor;
	font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    user-select: none;
    color: white;
    border-radius:2px;
    margin-top: 15px;
    border: 1px solid darkblue;
    @media screen and (max-width: 767px){
		padding: 0.4rem 0.5rem;
		font-size: 13px;
		margin-top: 8px;
	}
    &:hover{
    	color:white;
    	background: #044b73;
    }
}
.comingsoon-topup-game{
	background:darkgray;
	font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    user-select: none;
    color: white;
    border-radius:2px;
    margin-top: 15px;
    border: 1px solid darkgray;
    @media screen and (max-width: 767px){
		padding: 0.4rem 0.5rem;
		font-size: 13px;
		margin-top: 8px;
	}
    &:hover{
    	color:white;
    	background: darkgray;
    }
}
.breadcrumb{
	margin:0;
	background-color:transparent;
}
#screenshots .item{
	background:transparent;
	padding:0;
	margin: 5px 20px 0px 0px;
}
#screenshots.owl-theme .owl-next {
    right: -27px;
    height: 252px;
    top: 44px;
    border-radius: 0;
    @media screen and(max-width:767px){
    	height: 100px;
    }
    &:hover{
    	background:transparent;
    	border:1px solid #efefef;
    }
}
#screenshots.owl-theme .owl-prev {
    left: -12px;
    height: 252px;
    top: 44px;
    border-radius: 0;
    @media screen and(max-width:767px){
    	height: 100px;
    }
    &:hover{
    	background:transparent;
    	border:1px solid #efefef;
    }
}

.owl-theme .owl-nav [class*="owl-"] {
	background:white;
	border:1px solid #efefef;
}
.detailscsho{
	width:auto !important;
	height:250px;
	@media screen and(max-width:767px){
		height: 100px;
	}
}

.screenshoot-back{
    background-size: cover;
    background-position: center top;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    height: 0;
    padding-bottom: 150%;
}
// .breadcrumb-item{
// 	float:none;
// 	display:inline-block;
// 	margin:0;
// 	vertical-align:middle;
// 	background:#f5f5f5;
// 	padding:20px;
// 	color:#6a6a6a;
// 	&:nth-child(2){
// 		background:#e5e5e5;
// 		a{
// 			padding: 0px 10px 0px 40px;
// 		}
// 		&::before{
// 			content: "";
// 		    width: 0;
// 		    height: 0;
// 	        border-top: 31px solid transparent;
// 		    border-bottom: 31px solid transparent;
// 		    border-left: 31px solid #f5f5f5;
// 		    position: absolute;
// 		    top: 0;
// 	        margin-left: -24px;
// 		}
// 	}
// 	&:nth-child(3){
// 		background:#f5f5f5;
// 		a{
// 			padding: 0px 10px 0px 40px;
// 		}
// 		&::before{
// 			content: "";
// 		    width: 0;
// 		    height: 0;
// 	        border-top: 31px solid transparent;
// 		    border-bottom: 31px solid transparent;
// 		    border-left: 31px solid #e5e5e5;
// 		    position: absolute;
// 		    top: 0;
// 	        margin-left: -24px;
// 		}
// 	}
// 	&:nth-child(4){
// 		background:#e5e5e5;
// 	    padding: 20px 30px 20px 30px;
// 	    span{
// 	    	padding:30px;
// 	    	position:relative;
// 	    	&::after{
//     		    content: "";
// 			    width: 0;
// 			    height: 0;
// 			    border-top: 31px solid transparent;
// 			    border-bottom: 31px solid transparent;
// 			    border-left: 31px solid #e5e5e5;
// 			    position: absolute;
// 			    top: 9px;
// 			    right: -90px;
// 	    	}
// 	    }
// 		&::before{
// 			content: "";
// 		    width: 0;
// 		    height: 0;
// 	        border-top: 31px solid transparent;
// 		    border-bottom: 31px solid transparent;
// 		    border-left: 31px solid #f5f5f5;
// 		    position: absolute;
// 		    top: 0;
// 	        margin-left: -34px;
// 		}
// 	}
// }
.qrcode-dn{
	font-size:em(150);
	color:#0a314a;
}
.h2-text-dn{
    font-size: em(12);
    font-weight: 500;
    color: #616161;
    display: inline;
}
.h5-text-dn{
    font-size: 17px;
    color: #373737;
    font-weight: 500;
    line-height: 1.6;
    @media screen and (max-width: 767px){
    	margin-left: 0;
    }
}

//end

//index frontend

#wrapper-duniappp{
    background: #dadada;
}
.wrapper-header-top{
    position: relative;
    margin: 0;
    background-color: #262626;
    color: white;
    height: auto;
    padding-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (max-width:767px){
    	display: none;
    }
    .pull-left{
    	.freehotline{
		    margin-top: 0.5rem;
		    a{
		    	&:hover{
		    		color: #2cb3ff;
		    	}
		    	img{
	    		    max-width: 100%;
    				height: 20px;
		    	}
		    }
    	}
    }
    .menu-top{
    	ul{
    		display: flex;
    		flex-flow: row wrap;
    		li{
    			margin-top: 0.5rem;
    			margin-right: 40px;
    			list-style: none;
    			a{
					text-decoration: none;
					&:hover{
			    		color: #2cb3ff;
			    	}
				}
    		}
    	}
    }
}

.app{
	display:block;
}

.skin-1{
	.main-panel > .header{
		background:#046eaa !important; 
	}
	.main-panel > .main-content {
		background:#dadada;
		// @media screen and (max-width:767px){
		// 	background:white;
		// }
	}
}
.main-panel > .header-secondary .navbar-heading, .main-panel > .header .navbar-heading{
	margin-top: 0.5rem;
}

.main-panel > .main-content > .content-view {
	@media screen and (max-width:767px){
		padding:0;
	}
}
.nopadding-mobile{
	@media screen and (max-width: 767px){
		padding:0 !important;
	}
}
.tabletviews{
	@media only screen and (max-width: 991px) and (min-width: 768px){
		width: 100% !important;
	}
	@media only screen and (max-width: 1199px) and (min-width: 992px){
		width: 100% !important;
	}
}

header {
  background: $maincolor;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 60px;
  @media screen and (max-width:767px){
  	height: 55px;
  }
  .navbar{
  	border:0;
  	display: inline-block;
    width: 100%;
    padding: 0rem 1rem;
  	.header-inner{
  		margin-top: 12px;
  	}
  }
}
.img-logor{
	@media only screen and (max-width: 991px) and (min-width: 768px){
		display: none !important;
	}
}
.header-top-section{
  	font-size: 0;
  	height: auto;	
  	margin:0;
  	li{
  		display: inline-block;
  		vertical-align: middle;
  		@media screen and (max-width:767px){
			font-size: initial;
  		}
  		&.logo-duniaplatform{
		    width: 14%;
		    padding-left:40px;
		    @media only screen and (max-width: 991px) and (min-width: 768px) {
		    	width: 20%;
		    	padding-left:25px;
		    }
		    
  		}
  		&.search-duniaplatform{
		    width: 35%;
		    .col-md-12{
		    	.navbar-search{
		    		@media only screen and (max-width: 767px) and (min-width: 546px){
						display: none;
		    		}
		    	}
		    }
  			@media screen and (max-width:767px){
  				width: 92%;
  			}
  			@media only screen and (max-width: 991px) and (min-width: 768px) {
				width: 50%;
  			}
  			@media only screen and (max-width: 1199px) and (min-width: 992px){
		        width: 33%;
			    margin-left: 15px;
  			}
  			@media only screen and (max-width: 767px) and (min-width: 546px){
			    width: 82%;
		    }
  		}
  		&.paymentlg-duniaplatform{
	        width: 28%;
  			text-align: right;
  			@media only screen and (max-width: 991px) and (min-width: 768px){
  				display: none;
  			}
  			@media only screen and (max-width: 767px) and (min-width: 500px){
  				display: none;
  			}
		    .menu-headDekstop{
	    	    font-size: 0;
			    margin: 0 auto;
			    text-align: right;
			    .android-head{
			    	font-size: 16px;
			    	vertical-align: middle;
			    	display: inline-block;
			    	margin-right: 55px;
			    	transition: color .5s, background .5s;
			    	@media only screen and (max-width: 1199px) and (min-width: 992px){
						margin-right: 36px;
			    	}
			    	a{
			    		-webkit-transition: color .5s, background .5s;
						transition: color .5s, background .5s;
			    		span{
					    	&:before{
							    content: "";
							    background: url(../img/icon/android-icon.png) no-repeat;
							    position: absolute;
							    background-size: 100%;
						        left: -27px;
							    width: 20px;
							    height: 23px;
							    top: -2px;
		  					}
		  					&:hover{
								color: #abd6ff;
							}
	  					}
	  					// span{
		  				// 	&:hover{
		  				// 		color: #a4c639;
		  				// 		&:before{
								//     content: "";
								//     background: url(../img/icon/android-icongreen.png) no-repeat;
								//     position: absolute;
								//     background-size: 100%;
							 //        left: -27px;
								//     width: 20px;
								//     height: 23px;
								//     top: -2px;
			  			// 		}
		  				// 	}
		  				// }
  					}
			    }
			    .blog-head{
			    	font-size: 16px;
			    	vertical-align: middle;
			    	display: inline-block;
			    	margin-right: 55px;
			    	@media only screen and (max-width: 1199px) and (min-width: 992px){
						margin-right: 36px;
			    	}
			    	a{
			    		-webkit-transition: color .5s, background .5s;
						transition: color .5s, background .5s;
						&:hover{
							color: #abd6ff;
						}
			    		span{
					    	&:before{
							    content: "";
							    background: url(../img/icon/blog-icon.png) no-repeat;
							    position: absolute;
							    background-size: 100%;
							    left: -30px;
							    width: 24px;
							    height: 24px;
							    top: -1px;
		  					}
		  					&:hover{
								color: #abd6ff;
							}
	  					}
	  					// span{
		  				// 	&:hover{
		  				// 		color: #ff1064;
		  				// 		&:before{
								//     content: "";
								//     background: url(../img/icon/blog-iconpink.png) no-repeat;
								//     position: absolute;
								//     background-size: 100%;
								//     left: -30px;
								//     width: 24px;
								//     height: 24px;
								//     top: -1px;
			  			// 		}
		  				// 	}
		  				// }
  					}
			    }
			    .paymenthead{
					font-size: 16px;
			    	vertical-align: middle;
			    	display: inline-block;
		    	    margin-right: 10px;
			    	@media only screen and (max-width: 1199px) and (min-width: 992px){
						margin-right: -12px;
			    	}
					a{
						span{
					    	&:before{
							    content: "";
							    background: url(../img/icon/payment-icon.png) no-repeat;
							    position: absolute;
							    background-size: 100%;
							    left: -26px;
							    width: 20px;
							    height: 23px;
							    top: 0px;
		  					}
		  					&:hover{
								color: #abd6ff;
							}
	  					}
	  					// span{
		  				// 	&:hover{
		  				// 		color: #fdc305;
		  				// 		&:before{
								//     content: "";
								//     background: url(../img/icon/payment-icongold.png) no-repeat;
								//     position: absolute;
								//     background-size: 100%;
								//     left: -26px;
								//     width: 20px;
								//     height: 23px;
								//     top: 0px;
			  			// 		}
		  				// 	}
		  				// }
  					}
				}
		    }
  			@media only screen and (max-width: 991px) and (min-width: 768px) {
				width: 12%;
  			}
  			@media only screen and (max-width: 1199px) and (min-width: 992px){
			    width: 28%;
  			}
  			a{

  				span{
  					color:white;
  					font-size: 14px;
  					vertical-align: middle;
  					font-weight: 600;
  					position:relative;
  					margin-left: 10px;	
  				}
  				img{
  					max-width: 100%;
  					height: 15.5px; 
  					vertical-align: middle;
  				}
  			}
  		}
  		&.loginregister-duniaplatform{
  			width: 20%;
  			float:right;
  			color: white;
  			@media only screen and (max-width: 991px) and (min-width: 768px) {
			    width: 30%;
  			}
  			@media only screen and (max-width: 1199px) and (min-width: 992px){
	            width: 23%;
  			}
  			.nav{
			    width: 100%;
			    height: auto;
			    
  			}
  		}
  	}
  }
.logindekst{
	margin-bottom:20px;
	@media screen and (max-width:767px){
		display: none;
	}
}  
.loginmobi{
	font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
	@media screen and (min-width:992px){
		display: none;
	}
}
.navbar-nav .nav-link{
	padding-top: 0.5rem !important;
	@media only screen and (max-width: 991px) and (min-width: 768px){
    	padding-top: 0.45rem !important;
	}
}
//menu 
.drawer-nav{
	background-color: $maincolor !important;
}

.drawer-hamburger{
	margin-left:30px;
	margin-top:0px;	
	@media screen and (max-width: 767px){
	    margin-left: 15px;
    	margin-top: -3px;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px){
		margin-left: 25	px;
	}
}
.drawer-open .drawer-hamburger-icon:after{
    width: 26px !important;
    top: -5px !important;
}
.drawer-open .drawer-hamburger-icon:before{
    width: 26px !important;
    top: -6px !important;
}
.drawer-hamburger-icon:after{
	width: 20px !important;
	top:7px !important;
}
.drawer-hamburger-icon:before{
	width: 32px !important;
	top: -7px !important;
}
.drawer-hamburger-icon{
	width: 25px !important;
}
.drawer-hamburger-icon, .drawer-hamburger-icon:after, .drawer-hamburger-icon:before {
	background-color:white !important;
    height: 2.50px !important;
    
}
.drawer--left .drawer-hamburger, .drawer--left.drawer-open .drawer-nav, .drawer--left.drawer-open .drawer-navbar .drawer-hamburger{
	outline:none;
}
.drawer-open .drawer-hamburger-icon{
	background-color:transparent !important;
}
.drawer--left.drawer-open .drawer-hamburger{
    left: -0.8rem !important;
    top: 0.25rem;
    @media screen and(max-width: 767px){
    	top: 0.5rem;
    }
}
.drawer-overlay{
	background-color: rgba(25, 24, 24, 0.45);
}
.drawer--left .drawer-nav{
	@media screen and(max-width:767px){
		background-color: $maincolor;
	}
}
//search
.navbar-search{
	position:relative;
	.form-control{
	    background-color: white;
		border-radius: 5px;
	    padding: .5rem 1.3rem;
	    color: $maincolor;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        border:0;
	    &::-webkit-input-placeholder{
	    	color: #ddd;
	    }
	    &:-moz-placeholder {
			color: #ddd;
	    }
	    &:-ms-input-placeholder {
			color: #ddd;
	    }
	    &::-ms-input-placeholder {
			color: #ddd;
	    }
	    &:placeholder-shown {
			color: #ddd;
	    }
	}
}
.onlymobilesearch{
	display:none;
	@media screen and(max-width: 767px){
		display: block;
	}
}
.searchmb-da{
    position: absolute;
    right: 0;
    margin-top: 15px;
    i{
    	color:white;
    	font-size: 30px;
    }
}
//banner
.bigBanner{
	overflow-x:hidden;
	margin-top: 10px;
	opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
	.bigBanner-cont{
	    margin-right: 10px;

		img{
		    max-width: 100%;
	    	height: 250px;
	    	@media screen and (max-width:767px)
	    	{
	    		height: 155px;
	    		border: 8px solid #ffffff;
	    	}
	    	@media screen and (min-width:991px) {
				height: 400px;
				border: 10px solid #ffffff;
	    	}
		}
	}	
}

.bigBanner.slick-initialized {
    visibility: visible;
    opacity: 1; 
}
.titleban{
    font-size: 12px;
    display: block;
    position: relative;
    padding: 10px;
    margin: 0px 0px -4px;
    width: 20%;
    text-align: center;
    color: $maincolor;
    @media screen and (max-width:767px){
    	display: none;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px){
    	display: none;
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px){
    	display: none;
    }
    span{
	    line-height: 20px;
	    text-transform: uppercase;
	    font-weight: 700;
        margin-left: -20px;
		&:after{
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    z-index: -1;
		    outline: 1px solid transparent;
		    border-radius: 10px 10px 0 0;
		    background: white;
		    content: '';
		    transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
		    transform-origin: 0 0;
		    backface-visibility: hidden;
		}
	}
}
//content menu
.user-login-dap{
    display: inline-block;
    margin: 50px 0px 0px 0px;
    position: relative;
    width: 100%;
    text-align: center;
	.user-login-dap-avatar{
		border-radius:50%;
		display: inline-block;
	    width: 120px;
		height: 120px;
		border: 3px solid white;
		img{
			border-radius:50%;
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			height: 100%;
		}
	}

}
.user-login-text{
	text-align:center;
    margin-top: 20px;
    color:white;
    span{
		font-size: 20px;
		display: block;
		font-weight: 500;
	}
	.promo-ham{
		margin-top:10px;
		small{
		    font-size: 11px;
    		font-weight: 200;
    		@media screen and (max-width:767px){
    			font-size: 15px;
    		}
		}
	}
}
.hamburger-menu-ul{
	font-size:0;
	width:75%;
	height:auto;
	margin-left: 35px;
    margin-top: 30px;
    padding: 0;
    @media screen and (max-width:767px){
    	margin-left: 50px;
    }
	li{
		font-size: initial;
		list-style: none;
        padding: 7px 0px;
	    border-bottom: 1px solid rgba(238, 238, 238, 0.14);
	    &:first-child{
    	    border-top: 1px solid rgba(238, 238, 238, 0.14);
	    }
		a{
			color: white;
			margin-left: 15px;
			transition: color .5s, background .5s;
			.ico-menu{
			    max-width: 100%;
			    height: 14px;
			    vertical-align: middle;
			}
			span{
				font-size: 14px;
    			vertical-align: middle;
    			margin-left: 10px;
			}
			&:hover{
				color: #86c4ff;
			}
		}

	}
}
//category
.bigBannercategory{
    overflow-x: hidden;
    @media screen and (max-width:767px){
    	display: none;
    }
    @media screen and (min-width:992px){
    	display: none;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px){
    	display: none;
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px){
    	display: none;
    }
    .card{
		background-color: transparent;
		margin-bottom:0;
		.card-block{
			.tab-content{
				background-color:transparent;
			    padding: 0.6rem;
			}
		}
    }
    #slideCategory{
    	.slick-track{
    		// width: 2000px !important;
	    	.slick-slide{
    		    margin-right: 10px;
    			width: 115px !important;
	    		a{
	    			white-space: initial;
    			    font-size: 10px;
				    text-transform: uppercase;
				    font-weight: 600;
			        min-height: 2rem;
    				line-height: 1.6;
	    		}
	    	}
	    }
    }
}

//product list home
.item-download-payment{
	font-size:0;
	width:100%;
	height:auto;
    padding: 0px 0px 0px 20px;
    margin: 0;
    margin-top: 12px;
    @media screen and(max-width: 767px){
	    padding: 0px 0px 0px 5px;
    }
	li{
		font-size: initial;
		vertical-align: middle;
		display: inline-block;
		img{
			max-width: 100%;
			height: 30px;
			@media screen and(max-width: 767px){
				height: 25px;
			}
		}
		&.download-b{
			width: 50%;
			text-align: center;
		    display: inline-block !important;
    		overflow: visible;
			@media screen and(max-width: 767px){
				width: 60%;
			}
		}
		&.pay-b{
			width: 50%;
			text-align: center;
			display: inline-block !important;
    		overflow: visible;
		}
		&.download-ba{
			width: 50%;
			text-align: center;
			@media screen and(max-width: 767px){
				width: 60%;
			}
		}
		&.pay-ba{
			width: 50%;
			text-align: center;
			display: inline-block;
		}
	}
}
.screenshoot-wrapper{
	margin-top:10px;
}
//slider
#listGamescarousel{
	padding: 5px 10px 10px 20px;
    position: relative;
    display: inline-block;
	.slick-track{
		@media screen and (max-width:767px){
			width: 1000px!important;
		}
		.slick-slide{
			@media screen and (max-width:767px){
				width: 130px!important;
			}
			img{
				margin:0 !important;
			}
		}
	}
}
#listGamescarousel .slick-arrow.slick-prev{
	left: auto;
    right: 20px;
    top: 18px;
    background: white;
    border-radius: 2px;
    border: 1px solid #ddd;
    width: 26px;
    height: 26px;
    margin-right: 58px;
    @media screen and (max-width:767px){
        top: auto;
    	margin-top: -7px;
    }
	    &:before{
    	    content: '\f053';
		    cursor: pointer;
		    font-family: 'FontAwesome';
		    color: #676364;
	        font-size: 15px;
	    }
	
}
.allexplore{
    position: relative;
    display: inline-block;
    width: 100%;
		.all-text-dn{
		background: white;
	    border: 1px solid #ddd;
	    font-size: 12px;
	    padding: 4px 10px;
	    display: inline;
	    color: #616161;
	    line-height: 1.2;

        position: absolute;
	    right: 0;
	    margin-right: 115px;
	    margin-top: 10px;
	    &:hover{
	    	color:#616161;
	    }
	}
}
#listGamescarousel .slick-arrow.slick-next{
    right: 40px;
    top: 18px;
    background: white;
    border-radius: 2px;
    border: 1px solid #ddd;
    width: 26px;
    height: 26px;
    @media screen and (max-width:767px){
        top: 18px;
    }
    &:before{
        content: '\f054';
    	cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #676364;
	    font-size: 15px;
	}
}
//product list detail
.img-listed-dp{
    display: block;
    margin: 15px 0px;
    width: 145px;
    height: 145px;
    @media screen and (max-width:767px){
    	width: auto;
    	height: auto;
    	max-width: 100%;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px){
    	width: 130px;
    	height: 130px;
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px){
	    width: 145px;
    	height: 145px;
    }
}
.paddingcartblock{
	padding: 2rem 2rem 0 2rem !important;
	@media screen and (max-width:767px){
    display: inline-block;
    padding: 30px;
    width: 100%;
    height: auto;
    position: relative;
    margin-left: 25px;
    margin-top: 1.5rem;
    padding:0rem !important;
	}
	.app-image{
		display: block;
		img{
			width:100%;
			padding: 0;
			border-radius: 20px;
			@media screen and (max-width:767px){
				width: 160px;
			}
			@media only screen and (max-width: 413px) and (min-width: 320px){
			    width: 130px;
			}
		}
		@media screen and (max-width:767px){
	    	padding: 0rem 0rem 0rem 0rem !important;
	        width: 140px;
			height: auto;
		}
		@media screen and (device-aspect-ratio: 2/3) {
			width: 100px;
		}
		@media screen and (device-aspect-ratio: 40/71) {
			width: 100px;
		}
	}
}
.paddingcarttextbutton{	
	@media screen and (max-width:767px){
    	padding: 0 5px !important;
	    margin-left: 30px;
	}
	@media only screen and (max-width: 360px) and (min-width: 320px){
		margin-left: 30px;
	}
	@media only screen and (max-width: 413px) and (min-width: 360px){
	    margin-left: 5px;
	}
}
//tabs
.tab-detailduniapp{
  position: relative;
  width: 100%;
  margin: 2em auto;
  z-index: 0;
  display: inline-block;
  padding: 0 2rem 0.75rem;
  @media only screen and (min-width: 768px) {
	    padding: 0 2rem;
  }
  @media screen and (max-width:767px){
  		padding: 0.75rem 1rem;
  		margin: 0em auto;
  }
  &:after{
  	content: "";
  	display: table;
  	clear: both;
  	@media only screen and (min-width: 768px) {
		display: none;
  	}
  }
  &::after{
  	position: absolute;
    top: 0;
    right: 0;
  	height: 60px;
 	width: 50px;
	z-index: 1;
	pointer-events: none;
	background: -webkit-linear-gradient( right , #f8f7ee, rgba(248, 247, 238, 0));
	background: linear-gradient(to left, #f8f7ee, rgba(248, 247, 238, 0));
	visibility: visible;
	opacity: 1;
	-webkit-transition: opacity .3s 0s, visibility 0s 0s;
	-moz-transition: opacity .3s 0s, visibility 0s 0s;
	transition: opacity .3s 0s, visibility 0s 0s;
  }
  nav{
  	overflow: auto;
	-webkit-overflow-scrolling: touch;
	
	@media only screen and (max-width: 991px) and (min-width: 768px) {
		position: relative;
	   	height: 100%;	  
	    z-index: 1;
	}
	@media only screen and (min-width: 960px) {
		position: relative;
    	float: none;
	    background: transparent;
	    box-shadow: none;
	}
  }
}
.tab-detailduniapp-navi{
	width: 100%;
	list-style:none;
    margin: 0;
    padding: 0;
    background: transparent;
	@media only screen and (min-width: 960px) {
	    width: 100%;
	    padding: 0;
	    border-bottom: 1px solid #efefef;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px){
	    width: 630px;
	}
	li{
		float: left;	
	    width: 25%;
	    border:1px solid #efefef;
	    border-left:none;
	    @media only screen and (min-width: 767px) {
		    text-align: center;
	        border-bottom: 0;
	    }
	    &:first-child{
	    	border-left:1px solid #efefef;
	    }
	}
	a{
	  position: relative;
      display: inline-block;
	  height: 40px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-size: 0.75rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 500;
      color: #808080;
      padding-top: 10px;
	  @media only screen and (min-width: 960px) {
	 	height: 60px;
    	line-height: 60px;
	    width: auto;
	    text-align: left;
	    font-size: 14px;
	    font-size: 0.875rem;
        padding: 0 5em 0;	
        text-align: center;  
      }
	  &::before{
	  	@media only screen and (min-width: 767px) {
	  		top: 22px;
	  	}
	  	@media only screen and (min-width: 960px) {
			top: 50%;
		    margin-top: -10px;
		    margin-left: 0;
		    left: 38px;
	  	}
	  }
	}
	a.selected{
		@media only screen and (min-width: 767px) {
			border-bottom: 2px solid $maincolor;
		}
		@media only screen and (min-width: 960px) {
		    border-bottom: 2px solid $maincolor;
		}
	}
	&:after{
		content: "";
		  display: table;
		  clear: both;
	}
}
.tab-detailduniapp-content{
	background: #ffffff;
    width: 100%;
    padding: 0;
    margin: 0;
    display: inline-block;
    height: auto !important;
	@media only screen and (min-width: 767px) {
		min-height: 480px;
	}
	@media only screen and (min-width: 960px) {
		min-height: 0;
	}	
	li{
		display: none;
  		padding:0;
  		@media only screen and (min-width: 767px) {
  			padding:0;
  		}
  		@media only screen and (min-width: 960px) {
  			padding:0;
  		}
  		p{
  		  font-size: 14px;
		  font-size: 0.875rem;
		  line-height: 1.6;
		  color: #565656;
		  margin-bottom: 2em;
			@media only screen and (min-width: 960px) {
			  	font-size: 16px;
    		    font-size: 0.9rem;
			}
  		}
	}
	li.selected{
	  display: inline-block;
	  width: 100%;
	  -webkit-animation: cd-fade-in 0.5s;
	  -moz-animation: cd-fade-in 0.5s;
	  animation: cd-fade-in 0.5s;
	}
}
.readMoreFade{
    bottom: -50px;
    display: none;
}

.no-touch .tab-detailduniapp-navi a:hover {
  color: #29324e;
  background-color: rgba(233, 230, 202, 0.3);
}
.tab-detailduniapp-navi a.selected {
  background-color: #ffffff !important;
  border-bottom: 2px solid $maincolor;
  color: #808080;
  width: 100%;
}
.tab-detailduniapp-navi a::before {
  /* icons */
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -10px;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/vicons.svg");
  background-repeat: no-repeat;
}
.tab-detailduniapp-navi a[data-content='details']::before {
  background-position: 0 0;
}
.tab-detailduniapp-navi a[data-content='reviews']::before {
  background-position: -20px 0;
}
.tab-detailduniapp-navi a[data-content='relateds']::before {
  background-position: -40px 0;
}

.tab-detailduniapp-navi a[data-content='details'].selected::before {
  background-position: 0 -20px;
}
.tab-detailduniapp-navi a[data-content='reviews'].selected::before {
  background-position: -20px -20px;
}
.tab-detailduniapp-navi a[data-content='relateds'].selected::before {
  background-position: -40px -20px;
}

.no-cssgradients .cd-tabs::after {
  display: none;
}
.tab-detailduniapp.is-ended::after {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}
@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cd-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#reviews{
	height:auto !important;
}
.tabshome{
  position: relative;
  width: 100%;
  margin: 0;
  z-index: 0;
  display: inline-block;
  padding: 0.75rem 2rem;
  @media only screen and (min-width: 768px) {
	padding: 0;
  }
  @media only screen and (max-width: 768px) {
	padding: 0;
	margin-top: 15px;
  }
  &:after{
  	content: "";
  	display: table;
  	clear: both;
  	@media only screen and (min-width: 768px) {
		display: none;
  	}
  }
  &::after{
  	position: absolute;
    top: 0;
    right: 0;
  	height: 60px;
 	width: 50px;
	z-index: 1;
	pointer-events: none;
	visibility: visible;
	opacity: 1;
	-webkit-transition: opacity .3s 0s, visibility 0s 0s;
	-moz-transition: opacity .3s 0s, visibility 0s 0s;
	transition: opacity .3s 0s, visibility 0s 0s;
  }
  nav{
  	overflow: auto;
	-webkit-overflow-scrolling: touch;
	
	@media only screen and (max-width: 991px) and (min-width: 768px) {
		position: relative;
	   	height: 100%;	  
	    z-index: 1;
	}
	@media only screen and (min-width: 960px) {
		position: relative;
    	float: none;
	    background: transparent;
	    box-shadow: none;
	}
  }
}
.tabshome-navi{
	width: 100%;
	list-style:none;
    margin: 0;
    padding: 0;
    background: transparent;
	@media only screen and (min-width: 960px) {
	    width: 100%;
	    padding: 0;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px){
	    width: 630px;
	}
	li{
		float: left;	
	    width: 25%;
	    border-left:none;
	    margin-left: -8px;
	    &:first-child{
	    	margin-left: 20px;

	    }
	    @media only screen and (max-width: 767px) {
		    text-align: center;
		    width: 48%;
	    }
	}
	a{
	  position: relative;
      display: inline-block;
	  height: 40px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-size: 0.75rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 500;
      color: white;
      padding-top: 10px;
      .lts-tab{
		&:before{
			content: "";
			background:url(../img/icon/latest-off.png) no-repeat;
		    width: 16px;
		    height: 16px;
		    position: absolute;
		    left: 45px;
		    top: 18px;
		    @media only screen and (max-width: 767px) {
				top: 12px;
				left: auto;
    			margin-left: -25px;
		    }
		    @media only screen and (max-width: 991px) and (min-width: 768px) {
		    	left: 22px;
	    		top: 12px;
		    }
		}	
	  }	
	  .exp-tab{
		&:before{
			content: "";
			background:url(../img/icon/world-off.png) no-repeat;
		    width: 16px;
		    height: 16px;
		    position: absolute;
		    left: 45px;
		    top: 18px;
		    @media only screen and (max-width: 767px) {
				top: 12px;
				left: auto;
    			margin-left: -25px;
		    }
		    @media only screen and (max-width: 991px) and (min-width: 768px){
		    	left: 20px;
    			top: 12px;
		    }

		}	
	  }	
	  @media only screen and (min-width: 960px) {
	 	height: 50px;
    	line-height: 50px;
	    text-align: left;
	    font-size: 14px;
	    font-size: 0.875rem;
        padding: 0;	
        text-align: center;  
	    color: white;
	    
      }
      &::after{
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    z-index: -1;
	    outline: 1px solid transparent;
	    border-radius: 10px 10px 0 0;
	    background: $maincolor;
	    content: '';
	    -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
	    transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
	    -webkit-transform-origin: 0 0;
	    transform-origin: 0 0;
	    -webkit-backface-visibility: hidden;
	    backface-visibility: hidden;
      }
	  &::before{
	  	@media only screen and (min-width: 767px) {
	  		top: 22px;
	  	}
	  	@media only screen and (min-width: 960px) {
			top: 50%;
		    margin-top: -10px;
		    margin-left: 0;
		    left: 38px;
	  	}
	  }
	}
	
	&:after{
		content: "";
		  display: table;
		  clear: both;
	}
}
.tabhome-content{
	background: #ffffff;
    width: 100%;
    padding: 0;
   	margin-top: -3px;
    display: inline-block;
    height: 0;
    .product-dapp{
	    border: 1px solid #f1f1f1;
	    box-shadow: none;
	    padding: 0px 5px;
	    margin-right: 10px;
	   
	    @media screen and (max-width:767px){
	    	border:0;
	    }
	    .img-listed-dp {
		    display: block;
		    margin: 15px 0px;
		    width: 100%;
		    height: 100%;
		    img{
	    	    max-width: 100%;
			    max-height: 100%;
			    height: 100%;
			    width: 100%;
			    display: block;
			    padding: 5px;
    			border-radius: 15px;
		    }
		}
		.card-block{
			padding: 0.5rem 0.5rem 3rem 0.5rem;background: #fff;white-space: nowrap;overflow: hidden;width: 95%;
			.pull-left{
				a{
					text-transform: uppercase;
				}
			}
			.title-game-d{
				color: #212121;
				font-weight:600;
				margin-bottom: 1em;
				@media screen and (max-width:767px){
					font-size: 12px;
				}
			}
			.descriptiononlydekstop{
				font-size:13px;color: #929292;
				-webkit-column-count: 1;
			    -moz-column-count: 1;
			    column-count: 1;	
			    -webkit-column-gap: 40px;
			    -moz-column-gap: 40px;
			    column-gap: 40px;
				@media screen and (max-width:767px){
					display: none;
				}
			}

		}
	}
	@media only screen and (min-width: 767px) {
		min-height: 480px;
	}
	@media only screen and (min-width: 960px) {
		min-height: 0;
	}	
	li{
		display: block !important;
	    height: 0px;
	    overflow: hidden;
        border-radius: 5px;
  		@media only screen and (min-width: 767px) {
  			padding:0;
  		}
  		@media only screen and (max-width: 767px){
			border-radius:0;
  		}
  		@media only screen and (min-width: 960px) {
  			padding:0;
  		}
  		p{
  		  font-size: 14px;
		  font-size: 0.875rem;
		  line-height: 1.6;
		  color: #565656;
		  margin-bottom: 2em;
			@media only screen and (min-width: 960px) {
			  	font-size: 16px;
    		    font-size: 0.9rem;
			}
  		}
	}
	li.selected{
	  display: inline-block;
	  width: 100%;
	  -webkit-animation: cd-fade-in 0.5s;
	  -moz-animation: cd-fade-in 0.5s;
	  animation: cd-fade-in 0.5s;
	  height: auto;
	  background:white;
	}
}

.rowproduct{
	@media screen and (min-width:992px){
		&:nth-child(6){
			clear: both;
		}
	}
	@media only screen and (max-width: 991px) and (min-width: 768px){
		&:nth-child(6){
			clear: both;
		}
	}
}

.no-touch .tabshome-navi a:hover {
  color: #29324e;
  background-color: rgba(233, 230, 202, 0.3);
}
.tabshome-navi a.selected {
  width: 100%;
  color: $maincolor;
  .lts-tab{
	&:before{
		content: "";
		background:url(../img/icon/latest-on.png) no-repeat;
	    width: 16px;
	    height: 16px;
	    position: absolute;
	    left: 45px;
	    top: 18px;
	    @media only screen and (max-width: 767px) {
			top: 12px;
		    left: auto;
    		margin-left: -25px;
	    }
	    @media only screen and (max-width: 991px) and (min-width: 768px) {
	    	left: 22px;
    		top: 12px;
	    }
	}	
  }
  .exp-tab{
	&:before{
		content: "";
		background:url(../img/icon/world-on.png) no-repeat;
	    width: 16px;
	    height: 16px;
	    position: absolute;
	    left: 45px;
	    top: 18px;
	    @media only screen and (max-width: 767px) {
			top: 12px;
			left: auto;
    		margin-left: -25px;
	    }
	}	
  }	
  &::after{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    outline: 1px solid transparent;
    border-radius: 10px 10px 0 0;
    background: white;
    content: '';
    -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
    transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.tabshome-navi a::before {
  /* icons */
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -10px;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/vicons.svg");
  background-repeat: no-repeat;
}
.tabshome-navi a[data-content='details']::before {
  background-position: 0 0;
}
.tabshome-navi a[data-content='reviews']::before {
  background-position: -20px 0;
}
.tabshome-navi a[data-content='relateds']::before {
  background-position: -40px 0;
}

.tabshome-navi a[data-content='details'].selected::before {
  background-position: 0 -20px;
}
.tabshome-navi a[data-content='reviews'].selected::before {
  background-position: -20px -20px;
}
.tabshome-navi a[data-content='relateds'].selected::before {
  background-position: -40px -20px;
}
.screenshoot-img{
    padding: 0px;
}
.description-product-dt{
	margin:0;
	display:block;
	.description{
		color: #333;
	}
}



//footer
footer {
  background: #0c2132;
  color: #fff;
  margin: 0;
  // &:before{
  //   content: "";
  //   bottom: 71px;
  //   height: 446px;
  //   left: 0;
  //   position: absolute;
  //   right: 0;
  //   background: transparent url(../img/icon/footer.jpg) no-repeat scroll 70% 100%;
  //   @media screen and (max-width:767px){
  //   	display: none;
  //   }
  // }
  @media screen and (max-width:767px){
    margin-top: -25px;
  }
}
footer .centered-duniaapp{
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

footer .centered-duniaapp .footer-contact {
  width: 20%;
}
footer .centered-duniaapp .footer-navigation {
  width: 60%;
}
.footer-links-holder{
	padding: 35px 0px;
	list-style:none;
}
footer .centered-duniaapp .footer-links-holder {
  width: 18.33333%;
  position: relative;
  float: left;
  margin: 0;
  padding: 35px 0px;
  @media screen and (max-width:767px){
  	width: 43.33333%;
  	padding:0;
  }
}
footer .centered-duniaapp .footer-links-holder .footer-links {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-transition: max-height .5s;
  transition: max-height .5s;
  margin-top: 30px;
}
footer .centered-duniaapp .footer-links-holder .footer-links li a {
    color: #9ca4ac;
	text-decoration: none;
	-webkit-transition: color .5s, background .5s;
	transition: color .5s, background .5s;
	@media screen and (max-width:767px){
		margin-right: 10px;
	}
}
footer .centered-duniaapp .footer-links-holder .footer-links li a:hover {
	color: #fff;
    text-decoration: none;
}
.footercopyright{
	color:#a7a7a7;	
	@media screen and (max-width:767px){
		text-align: center;
	}
}
/* Responsive Tablet Sizes */
@media only screen and (max-width: 749px) {
  footer .centered {
    flex-wrap: wrap;
  }
  footer .centered-duniaapp  .footer-logo {
    max-width: 250px;
    width: 50%;
  }
  footer .centered-duniaapp  .footer-contact {
    width: 50%;
    padding: 0 20px;
  }
  footer .centered-duniaapp  .footer-navigation {
    width: 100%;
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  footer .centered-duniaapp  .footer-logo {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }
  footer .centered-duniaapp  .footer-contact {
    width: 100%;
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder {
    width: 100%;
    padding: 0;
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder h3 {
    margin: 0;
    padding: 10px 20px;
    border-top: #13a9ee 1px solid;
    cursor: pointer;
    /* Down Arrows */
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder h3::after {
    float: right;
    margin-right: 10px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-top: 7px;
    width: 7px;
    height: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: transform .5s;
    transition: transform .5s;
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder .footer-links {
    max-height: 0;
    overflow: hidden;
    
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder.active h3::after {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  footer .centered-duniaapp  .footer-navigation .footer-links-holder.active .footer-links {
    max-height: 10000px;
  }
}

//loader
.loaderduniapp{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:9999;
	background: url(../img/loading.gif) 50% 50% no-repeat rgb(249,249,249);
	opacity: .8;
}


//login register
.login-ov-card{
	.card{
		.card-header{
		    padding: 20px 40px 10px;
		    @media screen and (max-width:767px){
		    	text-align: center;
		    }
		}
		.card-block{
			padding: 0.75rem 2.5rem;margin-top: 20px;
		}
	}
}
.login-register-ct{
	background:$maincolor;
	padding: 35px;
    height:500px;
	.login-register-head{
		padding:0.75rem 1rem;
		position:relative;
		h2{
			color:white;
		}
	}
	.login-register-content{
		padding:0.75rem 1rem;
		p{
			color: white;
		}
	}
	.login-register-cta{
        padding: 4.2rem 1rem 0;
	}
}
.register-login-ct{
	background:$maincolor;
	padding: 35px;
    height: 500px;
	.login-register-head{
		padding:0.75rem 1rem;
		position:relative;
		h2{
			color:white;
		}
	}
	.login-register-content{
		padding:0.75rem 1rem;
		p{
			color: white;
		}
	}
	.login-register-cta{
        padding: 3rem 1rem 0;
	}
}

.socialmed.facebook {
    background: #3b5999;
    color: white !important;
    display: inline-block;
    margin-bottom: 0px;
    border-radius: 2px;
    margin-top: 8px;
    height: 40px;
    margin: auto;
    position: relative;
    padding: 0.5rem 4rem;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    text-indent: 10px;
    width: 300px;
    margin-right: 10px;
    @media screen and (max-width:767px){
	    width: 100%;
	    text-align: center;
	    margin-right: 0;
    }
	&:before{
	    content: "\f230";
	    font-size: 1.5em;
	    font-family: FontAwesome;
	    position: absolute;
	    left: 0;
	    max-width: 100%;
	    text-indent: 25px;
	    top: 4px;
	    @media screen and (max-width:767px){
	        left: 25px;
	    }
	    @media screen and (device-aspect-ratio: 2/3) {
			left: 10px;	
	    }
	    @media screen and (device-aspect-ratio: 40/71) {
			left: 10px;	
	    }
	}
}



.socialmed.google {
    background: white;
    color: #333 !important;
    display: inline-block;
    margin-bottom: 0px;
    border-radius: 2px;
    margin-top: 8px;
    height: 40px;
    margin: auto;
    position: relative;
    padding: .5rem 4rem;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid #e6e6e6;
    width: 300px;
    @media screen and (max-width:767px){
	    width: 100%;
	    text-align: center;
    }
	&:before{
		content: url(../img/googlelogo.png);
	    position: absolute;
	    left: 0;
	    max-width: 100%;
	    text-indent: 20px;
	    top: 9px;
	    @media screen and (max-width:767px){
	        left: 25px;
	    }
	    @media screen and (device-aspect-ratio: 2/3) {
			left: 10px;	
	    }
	    @media screen and (device-aspect-ratio: 40/71) {
			left: 10px;	
	    }
	}
}
.regwithemail{
	@media screen and (min-width:992px){
		display: none;
	}
	font-size: 15px;
    color: #444;
    font-weight: 400;
    margin-bottom: 20px;
}

.styling-btn{
	background: #0695e4;
	border-color: #0695e4;
	color:white;
	border:none;
	padding: 10px 20px;
    text-align: left;
    transition: color .5s, background .5s;
    @media screen and (max-width:767px){
    	text-align: center;
    }
	&:after{
		content: "\f105";
		font-family: FontAwesome;
		position:absolute;
	    right: 0;
    	margin-right: 15px;
	}
	&:hover{
		background:#0776b3;
		border-color: #0695e4;
	}
	&:active{
		background:#0776b3;
		border-color: #0695e4;
	}
	&:focus{
		background:#0776b3;
		border-color: #0695e4;
	}
}
.styling-reg{
	background: transparent;
	border: 1px solid white;
	color:white;
	padding: 10px 20px;
    text-align: left;
    transition: color .5s, background .5s;
    &:after{
		content: "\f105";
		font-family: FontAwesome;
		position:absolute;
	    right: 0;
    	margin-right: 15px;
	}
    &:hover{
		background:white;
		border-color: #0695e4;
		color: #0776b3;	
	}
	&:active{
		background:#0776b3;
		border-color: #0695e4;
	}
	&:focus{
		background:#0776b3;
		border-color: #0695e4;
	}
}
.sharetosocmed{
    font-size: 15px;
    color: #909090;
    font-weight: 500;
}
.forgotpass{
	float:right;
	color:#3584ab;
	font-size: 11px;
    font-weight: 400;
}
/* Typography */
footer h3 {
  margin-bottom: 0;
  font-size: 18px;
}
footer p, footer li {
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 10px 0;
}
//social media
#social{
	margin-bottom:10px;
	@media screen and (max-width: 767px){
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
}
.smGlobalBtn { /* global button class */
    display: inline-block;
    position: relative;
    cursor: pointer;	
    width: 40px;
    height: 40px;
    border:2px solid #ddd; /* add border to the buttons */
    padding: 0px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
    line-height: 2em;
    border-radius: 27px;
    -moz-border-radius:27px;
    -webkit-border-radius:27px;
}
.facebookBtn{
	text-indent:-66666em;
	background:url(../img/fb_icon.png)center no-repeat;
	display:inline-block;
	background-size:contain;
	width:40px;
	height:40px;
}
.twitterBtn{
	text-indent:-66666em;
	background:url(../img/tw_icon.png)center no-repeat;
	display:inline-block;
	background-size:contain;
	width:40px;
	height:40px;
}
.instagramBtn{
	text-indent:-66666em;
	background:url(../img/ig_icon.png)center no-repeat;
	display:inline-block;
	background-size:contain;
	width:40px;
	height:40px;
}
/* Misc CSS */
.clearfix:before, .clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
  clear: both;
}

//profile
.profileSlide{
	@media screen and (max-width:767px){
		display: none!important;
	}
	.profileSlide-img{
	    background-size: cover;
	    background-position: center top;
	    overflow: hidden;
	    position: relative;
	    margin: 0 auto;
	    max-width: 1920px;
	    height: 0;
	    padding-bottom: 19%;
	}
}
.profil-section{
	position:relative;
	@media screen and (max-width:767px){
	    margin: 0;
	    background: white;
	    padding-top: 100px;
	}
	.profil{
		font-size:0;
		width:100%;
		height:auto;
		display: inline-block;
    	background: white;
    	padding: 10px;
	    border: 0.5px solid rgba(167, 167, 167, 0.59);
	    @media screen and (max-width:767px){
			border:0;
	    }
		li{
			font-size: initial;
			display: inline-block;
			vertical-align: middle;
			&.profil-name{
				width: auto;
				margin-right: 10px;
			    margin-left: 225px;
		        font-weight: 500;
		        @media screen and (max-width:767px){
					width: 100%;
    				text-align: center;
    				margin-right: 0	px;
			    	margin-left: 0px;
    				padding-top: 50px;
		        }
		        @media only screen and (max-width: 991px) and (min-width: 768px){
		        	margin-left: 215px;
		        }
			    h1{
					font-size: 27px;
					color: #323232;	
			    }
			}
			&.profil-socmed{
				width: 15%;
				margin-left: 10px;
				@media screen and (max-width:767px){
					width: 100%;
					margin-left:0;
					text-align:center;
				}
				@media only screen and (max-width: 991px) and (min-width: 768px){
					width: 18%;
				}
				img{
					max-width: 100%;
    				height: 24px;
				}
				a{
					margin-right: 10px;
				}
			}
			&.profil-menus{
			    width: 40%;
				@media screen and (max-width:767px){
					display: none;
				}
				@media only screen and (max-width: 991px) and (min-width: 768px){
			        width: 85%;
				}
				@media only screen and (max-width: 1199px) and (min-width: 992px){
					width: 62%;
				}
				ul{
					text-align: right;
					li{
						margin-right: 27px;
						a{
							color:#4f4f4f;
						    font-size: 16px;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
.editprofile-head{
	padding: 15px 0px 15px 20px;
    border-bottom: 1px solid rgba(169, 169, 169, 0.54);
    background:#f5f5f5;
    @media screen and (max-width:767px){
		border-bottom:0;
		padding: 15px 0px 15px 36px;
    }
}
.profil-photoupload{
    position: absolute;
    top: -110px;	
    margin-left: 50px;
    border: 7px solid white;
    border-radius:50%;
    @media screen and (max-width:767px){
		top: -35px;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-left: 0;
    }
}
.profil-pic-u{
	height: 150px;
	width: 150px;
	background-size: cover;
	background-position:center;
	background-blend-mode:multiply;
	vertical-align: middle;
    text-align: center;
    color: transparent;
    transition: all .3s ease;
    text-decoration: none;
    border-radius:50%;
    
    span{
    	display: inline-block;
	    padding-top: 4.5em;
	    padding-bottom: 4.5em;
    }
    
}
#imageUpload{
	display: none;
}
.profil-content-cms{
    margin-top: 5px;
    position: relative;
    width: 100%;
    display: inline-block;
    @media screen and (max-width:767px){
    	margin-top: 0;
    }
}
.label-icon{
    border-radius: 7em;
    border: 1px solid #c1c1c1;
    padding: 0px 5px;
    width: 2em;
    height: 2em;
    display: inline-block;
    img{
    	max-width: 100%;
	    height: 15px;
    }
}
.wraptable-user{
	.table {	
		tr{
			border: 1px solid rgba(208, 208, 208, 0.05);
			border-top:0;
			td{
			    border: 1px solid rgba(140, 140, 140, 0.29);
				border-top:1px solid rgba(140, 140, 140, 0.29);
		        padding: 5px 10px 5px 5px !important;
    			vertical-align: middle;
    			@media screen and (max-width:767px){
					display: block;
					border:0;
					border-top:0;
    			}
    			@media only screen and (max-width: 991px) and (min-width: 768px) {
    				display: block;
    				border:0;
					border-top:0;
    			}
				&:nth-child(odd){
					background:#f5f5f5;
					@media screen and (max-width:767px){
						background: white;
					}
					@media only screen and (max-width: 991px) and (min-width: 768px) {
						background: white;
					}
				}
			}
		}
		th{
			border: 1px solid rgba(208, 208, 208, 0.05);
			border-top:0;
		}
	}
}

.btn-editprofile{
	background:$maincolor;
	border:0;
	&:hover{
		background:$maincolor;
	}
}
.btn-requestdev{
	background:$maincolor;
	border:0;
	&:hover{
		background:$maincolor;
	}
}
// class hide for mobile
.hidemobile-duniapp{
	@media screen and (max-width:767px){
		display: none;
	}
}
.showmobile-duniapp{
	display:none;
	@media screen and (max-width:767px){
		display: block;
	}
	li{
		display: block;
	}
}


.nav .open > a {
    background-color: transparent;
}

    .nav .open > a:hover {
        background-color: transparent;
    }

    .nav .open > a:focus {
        background-color: transparent;
    }

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

    #wrapper.toggled {
        padding-left: 220px;
    }

        #wrapper.toggled #sidebar-wrapper {
            width: 220px;
        }

        #wrapper.toggled #page-content-wrapper {
            margin-right: -220px;
            position: absolute;
        }

#sidebar-wrapper {
    background: #1a1a1a;
    height: 100%;
    left: 220px;
    margin-left: -220px;
    overflow-x: hidden;
    overflow-y: auto;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 0;
    z-index: 1000;
}

    #sidebar-wrapper::-webkit-scrollbar {
        display: none;
    }


#page-content-wrapper {
    padding-top: 70px;
    width: 100%;
}


.sidebar-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 220px;
}

    .sidebar-nav li {
        display: inline-block;
        line-height: 20px;
        position: relative;
        width: 100%;
    }

        .sidebar-nav li:before {
            background-color: #1c1c1c;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            -webkit-transition: width 0.2s ease-in;
            -moz-transition: width 0.2s ease-in;
            -ms-transition: width 0.2s ease-in;
            -o-transition: width 0.2s ease-in;
            transition: width 0.2s ease-in;
            width: 3px;
            z-index: -1;
        }

        .sidebar-nav li:before {
            background-color: #6bb4e7;
        }

        .sidebar-nav li:hover:before {
            -webkit-transition: width 0.2s ease-in;
            -moz-transition: width 0.2s ease-in;
            -ms-transition: width 0.2s ease-in;
            -o-transition: width 0.2s ease-in;
            transition: width 0.2s ease-in;
            width: 100%;
        }

        .sidebar-nav li a {
            color: #dddddd;
            display: block;
            padding: 10px 15px 10px 30px;
            text-decoration: none;
        }

        .sidebar-nav li.open:hover before {
            -webkit-transition: width 0.2s ease-in;
            -moz-transition: width 0.2s ease-in;
            -ms-transition: width 0.2s ease-in;
            -o-transition: width 0.2s ease-in;
            transition: width 0.2s ease-in;
            width: 100%;
        }

    .sidebar-nav .dropdown-menu {
        background-color: #222222;
        -ms-border-radius: 0;
        border-radius: 0;
        border: none;
        -webkit-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
    }

    .sidebar-nav li a:hover, .sidebar-nav li a:active, .sidebar-nav li a:focus, .sidebar-nav li.open a:hover, .sidebar-nav li.open a:active, .sidebar-nav li.open a:focus {
        background-color: transparent;
        color: #ffffff;
        text-decoration: none;
    }

    .sidebar-nav > .sidebar-brand {
        font-size: 20px;
        height: 65px;
        line-height: 44px;
    }

.hamburger {
    background: transparent;
    border: none;
    display: block;
    height: 32px;
    margin-left: 15px;
    position: fixed;
    top: 20px;
    width: 32px;
    z-index: 999;
    &:hover{
		outline: none;
    }
    &:focus{
		outline: none;
    }
    &:active{
		outline: none;
    }
}

.hamburger.is-closed{
	&:before {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    content: '';
    display: block;
    font-size: 14px;
    line-height: 32px;
    -ms-opacity: 0;
    opacity: 0;
    text-align: center;
    width: 100px;
	}
	&:hover{
		.hamb-top{
			-webkit-transition: all 0.35s ease-in-out;
	        -moz-transition: all 0.35s ease-in-out;
	        -ms-transition: all 0.35s ease-in-out;
	        -o-transition: all 0.35s ease-in-out;
	        transition: all 0.35s ease-in-out;
	        top: 0;
		}
	}
}
.hamburger.is-closed:hover before {
    -webkit-transform: translate3d(-100px, 0, 0);
    -moz-transform: translate3d(-100px, 0, 0);
    -ms-transform: translate3d(-100px, 0, 0);
    -o-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: block;
    -ms-opacity: 1;
    opacity: 1;
}
.hamburger.is-closed:hover .hamb-bottom {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    bottom: 0;
}

.hamburger.is-closed .hamb-top {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    top: 5px;
}

.hamburger.is-closed .hamb-middle {
    margin-top: -2px;
    top: 50%;
}

.hamburger.is-closed .hamb-bottom {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    bottom: 5px;
}

.hamburger.is-closed .hamb-top, .hamburger.is-closed .hamb-middle, .hamburger.is-closed .hamb-bottom, .hamburger.is-open .hamb-top, .hamburger.is-open .hamb-middle, .hamburger.is-open .hamb-bottom {
    height: 4px;
    left: 0;
    position: absolute;
    width: 100%;
}

.hamburger.is-open .hamb-top {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    -moz-transition: -moz-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    -ms-transition: -ms-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    -o-transition: -o-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    margin-top: -2px;
    top: 50%;
}

.hamburger.is-open .hamb-middle {
    display: none;
}

.hamburger.is-open .hamb-bottom {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    -moz-transition: -moz-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    -ms-transition: -ms-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    -o-transition: -o-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
    margin-top: -2px;
    top: 50%;
}

.hamburger.is-open:before {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    content: '';
    display: block;
    font-size: 14px;
    line-height: 32px;
    -ms-opacity: 0;
    opacity: 0;
    text-align: center;
    width: 100px;
}

.hamburger.is-open:hover before {
    -webkit-transform: translate3d(-100px, 0, 0);
    -moz-transform: translate3d(-100px, 0, 0);
    -ms-transform: translate3d(-100px, 0, 0);
    -o-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: block;
    -ms-opacity: 1;
    opacity: 1;
}

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.hamb-bottom, .hamb-middle, .hamb-top {
    background-color: black;
}

.buttonlogin-ham{
    display: inline-block;
    border: 1px solid #ddd;
    padding: 10px;
    width: 72.3%;
    margin-bottom: 10px;
    background: white;
    color: $maincolor;
    position:relative;
    border-radius: 2px;
    -webkit-transition: color .5s, background .5s;
	transition: color .5s, background .5s;
    &:before{
    	content: "";
    	background: url(../img/login-icon.png)no-repeat;
    	position:absolute;
    	width: 14px;
    	height: 19px;
    	left: auto;
    	margin-left: -25px;
    }
    &:hover{
	    background: #e2f1ff;
    	color: $maincolor;
    }
	img{
		max-width: 100%;
	    height: 16px;
    	vertical-align: top;
	}
	span{
		font-size: 15px;
		display: inline-block;
		font-weight: 600;
	}
}
.buttonregister-ham{
    display: inline-block;
    border: 1px solid #ddd;
    padding: 10px;
    width: 72%;
    color: white;
    border-radius: 2px;
    -webkit-transition: color .5s, background .5s;
	transition: color .5s, background .5s;
    &:before{
    	content: "";
    	background: url(../img/user-icon.png)no-repeat;
    	position:absolute;
    	width: 17px;
    	height: 17px;
    	left: auto;
	    margin-left: -5px;
    	margin-top: 2px;
    }
    &:hover{
        background: #14b3ff;
    	color: white;
    }
	img{
		max-width: 100%;
	    height: 16px;
    	vertical-align: top;
	}
	span{
		font-size: 15px;
		display: inline-block;
		font-weight: 600;
		margin-left: 20px;
	}
}
[data-ratings]{
	display:inline;
}
[data-ratings] svg {
    width: 16px;
    height: 16px;
    fill: #fdc305;
}
.profile-timeline-content{
	margin-left: 90px;
	p{
		font-size: 12px;
		display: inline-block;
	}
}
.profile-timeline-header{
	.profile-timeline-user{
		img{
		    max-width: 100%;
    		height: 75px;
		}
	}
}

.malefemale{
	cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  font-size: 12px;
	vertical-align: bottom;
  i{
  	display: inline-block;
	vertical-align: middle;
	width:  16px;
	height: 16px;
	border-radius: 50%;
	transition: 0.2s;
	box-shadow: inset 0 0 0 8px #fff;
	border: 1px solid gray;
	background: gray;
  }
  &:hover > i{
	box-shadow: inset 0 0 0 3px #fff;
  	background: gray;
  }
}	
.malefemale > input:checked + i{
  box-shadow: inset 0 0 0 3px #fff;
  background: $maincolor;
}
.malefemale > input{
	visibility:hidden;
	position:absolute;
}
.adsimg{
    max-width: 100%;
}
.height-mb{
    height: 100%;
    min-height: 500px;
    @media screen and (max-width:767px){
    	min-height: 600px;
    }
}
.heightreg-mb{
    height: 100%;
    min-height: 500px;
    @media screen and (max-width:767px){
	    min-height: 840px;
    }
}
.notmember-register{
    margin-top: 15px;
    display: inline-block;
	@media screen and (min-width:992px){
		display: none;
	}
	span{
		display: block;
	    font-size: 13px;
	    color: #9e9e9e;
	}
	a{
		color: #05517b;
    	font-weight: 500;
	}
}
.autocomplete-suggestions {
    border-radius: 5px;
    .autocomplete-suggestion{
    	padding: .6em .7em;
    	color:$maincolor;
    	cursor:pointer;
    	span{
    		margin-left:20px;
    		font-weight: 500;
    		font-size: 1rem;
    	}
		img{
		    max-width: 100%;
		    height: 80px;
		    width: 80px;
		}
	}
}
.relatehomeul{
	font-size:0;
	width:100%;
	height:auto;
	li{
		font-size: initial;
  		display: inline !important;
  		vertical-align: middle;
		&.displayimage-link{
		    width: 22.5490196078%;
		}
		&.displaytitle-desc{
		    width: 34.7058823529%;
		}
		&.displayimg-sl{
			    width: 42.7450980392%;
		}
	}
}
.gridlatestgame{
	padding:15px;
	.row{
		margin-bottom: 25px;
	}	
}
.wrapper-itemlinktab{
	margin: 5px 0px;
    position: relative;
    padding: 10px 30px;
    display: inline-block;
    width: 100%;
    @media screen and (max-width:767px){
    	margin:0;
    	padding:0 10px;
    }
    &:before{
        content: "";
	    position: absolute;
	    left: 0;
	    right: 0;
	    height: 0.5em;
	    border-bottom: 1px solid #b9b9b945;
	    width: 93%;
	    margin-left: auto;
	    margin-right: auto;
	    bottom: -15px;
	    display: block;
    }
    .left-i{
		float: left;
		width: 20%;
		text-align:center;
	    padding: 0px 10px;
		@media screen and (max-width:767px){
			padding: 0;
    		width: auto;
		}
		.section-img-linkdetail{
			text-align:center;
			position:relative;
			.section-img-detail{
			    img{
			    	display: block;
				    width: 100%;
				    height: auto;
				    margin: auto;
				    border-radius:25px;
			    	@media screen and (max-width:767px){
					    width: 96px;
    					height: 96px;
					}
			    }
			    
			}
		}
	}
	.middle-i{
		position:relative;
	    width: 35%;
	    float: left;
	    overflow:hidden;
        padding: 0px 30px;
        height: 229px;
        @media screen and (max-width:767px){
	        float: none;
		    width: auto;
		    padding: 0;
	        height: 120px;
		    max-height: 260px;
		    margin-left: 111px;
		}
        .game-title{
		    font-size: 1rem;
		    font-weight: 600;
		    color: #212121;
		    margin:0;
	        overflow: hidden;
    		white-space: nowrap;
    		text-overflow: ellipsis;
		    @media screen and (max-width:767px){
				white-space:normal;
		    }
		    @media screen and (device-aspect-ratio: 2/3) {
				white-space: nowrap;
		    }
		    @media screen and (device-aspect-ratio: 40/71) {
				white-space: nowrap;
		    }
        }
        .my-ratings{
			@media screen and (max-width:767px){
				zoom: 1;
			    -moz-transform: scale(0.6);
			    -moz-transform-origin: 0 0;
			    -o-transform: scale(0.6);
			    -o-transform-origin: 0 0;
			    -webkit-transform: scale(0.6);
			    -webkit-transform-origin: 0 0;
			    transform: scale(0.6);
			    transform-origin: 0 0;
			    margin-top: -10px;
			}
		}
        small{
    	    display: block;
	        margin: 0px 0px 15px;
        	a{
				color: #6fa57c;
				text-decoration: underline;
			    font-weight: 500;
			    text-transform: uppercase;
			    font-size: 0.8rem;
			    @media screen and (max-width:767px){
		    	    font-size: .6rem;
			    }
        	}
        }
        .descriptiononlydekstop{
        	white-space: normal;
    	    margin: 0px 0px 15px;
    	    color: #929292;
    	    -webkit-column-count: 1;
		    -moz-column-count: 1;
		    column-count: 1;
			white-space: normal;	
		    -webkit-column-gap: 40px;
		    -moz-column-gap: 40px;
		    column-gap: 40px;
			height: 60px;
        	@media screen and (max-width:767px){
        		display: none;
        	}
        }
        .parent-cat{
        	color: $maincolor;
        	font-weight: 500;
        	font-size: 1rem;
    	    display: inline-block;
    		margin-right: 5px;margin-bottom: 15px;
    		text-transform: capitalize;
    		@media screen and (max-width:767px){
    			display: none;
    		}
        }
        .category-child{
        	position:relative;
    	    margin-top: 20px;
    		width: calc(100% + 50px);
        	@media screen and (max-width:767px){
        		bottom:14px;
    		    left: 0;
    		    margin-top: 0;
        	}
	        a{
		        padding: 0 5px 0px 0px;
		        .cat-title{
		        	font-size: 11px;
		        	color: $maincolor;
		        	padding:5px;
		        	border:1px solid #5897d4;
		        	@media screen and (max-width:767px){
		        		padding:2px;
		        	}
		        }
	    	}
    	}
	}
	.right-i{
		float: left;
		width: 45%;
		overflow:hidden;
		@media screen and (max-width:767px){
			display: none;
		}
		.screenshoot-back{
		    background-size: cover;
		    background-position: center top;
		    overflow: hidden;
		    position: relative;
		    margin: 0 auto;
	        height: 220px;
			width: 110px;
		}
	}
}
.detail-btn{
	background: $maincolor;
    color: white;
    width: 100%;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0px;
    transition: color .5s, background .5s;
    margin-top: 33px;
    &:hover{
    	background:#0e3e6d;
    	color: white;
    }
}

#modalFacebook.modal{
	background: white;
    width: 50%;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.category-sidebar{
	display:inline;
	.list-unstyled{
	    padding: 5px 5px 0px 10px;
   	 	margin: 0;
		li{
			display: inline-block;
			a{
				font-size: 12px;
				min-height:0;
			}
		}
	}
}
.comsoon{
	position: absolute;
  	left: -5px; top: -5px;
  	z-index: 1;
  	overflow: hidden;
  	width: 75px; height: 75px;
  	text-align: right;
	span{
		font-size: 11px;
    	font-weight: 500;
		color: #FFF;
		text-transform: capitalize;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: #fe181b;
	    background: linear-gradient(#fe4d67 0%, #fe181b 100%);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px; left: -21px;
		&::before{
			content: "";
			position: absolute; left: 0px; top: 100%;
			z-index: -1;
			border-left: 3px solid #fe181b;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #fe181b;
		}
		&::after{
		  content: "";
		  position: absolute; right: 0px; top: 100%;
		  z-index: -1;
		  border-left: 3px solid transparent;
		  border-right: 3px solid #fe181b;
		  border-bottom: 3px solid transparent;
		  border-top: 3px solid #fe181b;
		}
	}
}
.comsoondetail{
	position: absolute;
	left: 7px;
    top: -4px;
  	z-index: 1;
  	overflow: hidden;
  	width: 120px; height: 120px;
  	text-align: right;
	span{
		font-size: 14px;
    	font-weight: 500;
		color: #FFF;
		text-transform: capitalize;
		text-align: center;
		line-height: 25px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 165px;
		display: block;
		background: #fe181b;
	    background: linear-gradient(#fe4d67 0%, #fe181b 100%);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
	    top: 37px;left: -33px;
		&::before{
			content: "";
			position: absolute; left: 0px; top: 100%;
			z-index: -1;
			border-left: 3px solid #fe181b;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #fe181b;
		}
		&::after{
		  content: "";
		  position: absolute; right: 0px; top: 100%;
		  z-index: -1;
		  border-left: 3px solid transparent;
		  border-right: 3px solid #fe181b;
		  border-bottom: 3px solid transparent;
		  border-top: 3px solid #fe181b;
		}
	}
}

.holder .jp-previous{
	border:0 !important;
	&:before{
	    content: '\f053';
	    cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #ddd;
	    font-size: 14px;
	    border: 1px solid #ddd;
	    padding: 5px 10px;
	    font-weight: 200;
	}
}

.holder .jp-previous.jp-disabled{
	&:before{
	    content: '\f053';
	    cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #ddd;
	    font-size: 14px;
	    border: 1px solid #ddd;
	    padding: 5px 10px;
	    font-weight: 200;
	}
}


.holder .jp-next{
	border:0 !important;
	&:before{
        content: '\f054';
    	cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #ddd;
	    font-size: 14px;
	    border: 1px solid #ddd;
	    padding: 5px 10px;
	    font-weight: 200;
	}
}
.holder a:hover{
	background-color: transparent !important;
}
.holder{
	margin: 25px 0 !important;
	a{
		border: 1px solid #ddd;
    	padding: 4px 10px;
	}
}
.holder a.jp-current, a.jp-current:hover {
	color:white !important;
	background:$maincolor !important;
}

.holders .jp-previous{
	border:0 !important;
	&:before{
	    content: '\f053';
	    cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #ddd;
	    font-size: 13px;
	    border: 1px solid #ddd;
	    padding: 5px 10px;
	    font-weight: 200;
	}
}

.holders .jp-previous.jp-disabled{
	&:before{
	    content: '\f053';
	    cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #ddd;
	    font-size: 13px;
	    border: 1px solid #ddd;
	    padding: 5px 10px;
	    font-weight: 200;
	}
}


.holders .jp-next{
	border:0 !important;
	&:before{
        content: '\f054';
    	cursor: pointer;
	    font-family: 'FontAwesome';
	    color: #ddd;
	    font-size: 13px;
	    border: 1px solid #ddd;
	    padding: 5px 10px;
	    font-weight: 200;
	}
}
.holders a:hover{
	background-color: transparent !important;
}
.holders a.jp-previous{
	margin-right:0;
}
.holders{
	margin: 25px 10px !important;
	a{
		border: 1px solid #ddd;
    	padding: 4px 10px;
	}
}
.holders a.jp-current, a.jp-current:hover {
	color:white !important;
	background:$maincolor !important;
}

.choose-file{
    width: 100%;
    height:auto;
    background-color: white;
    position: relative;
    border: 1px solid #e5e5e5;
    input[type='file'] {
	    width:100%;
	    height:25px;
	    opacity:0
	}
	#val {
	    width:100%;
	    height:25px;
	    position: absolute;
	    top: 0;
	    left: 0;
	    font-size:13px;
	    line-height: 25px;
	    text-indent: 10px;
	    pointer-events: none;
	}
	#button {
	    cursor: pointer;
	    display: block;
	    width: 90px;
	    background-color: $maincolor;
	    height:25px;
	    color: white;
	    position: absolute;
	    right:0;
	    top: 0;
	    font-size: 11px;
	    line-height:25px;
	    text-align: center;
	    -webkit-transition: 500ms all;
	    -moz-transition: 500ms all;
	    transition: 500ms all;
	    &:hover{
			background-color:#02315f;
	    }
	}
}

.facebook-congrat{
    text-align: center;
    position: absolute;
    width: 100%;
    top: 30%;
	h2{
		color: $maincolor;
    	font-size: 40px;
    	font-weight: 700;
	}
	i{
		font-size: 80px;
		color: $maincolor;
	}
	p{
		color:#909090;
	}
}

.google-congrat{
    text-align: center;
    position: absolute;
    width: 100%;
    top: 30%;
	h2{
		color: $maincolor;
    	font-size: 40px;
    	font-weight: 700;
	}
	i{
		font-size: 80px;
		color: #333;
	}
	p{
		color:#909090;
	}
}

.jssocials-share-link{
	border-radius:5px;
}
#qrcode{
	margin-top:10px;
	canvas{
		max-width: 100%;
		width: 100px;
		height: 100px;
		max-height: 100%;
	}
}

.lightbox {
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  position: fixed;
  display: none;
  z-index: 9999;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.lightbox-container {
  position: relative;
  max-width: 960px;
  margin: 7% auto;
  display: block;
  padding: 0 3%;
  height: auto;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .lightbox-container {
    margin-top: 10%;
  }
}
@media screen and (max-width: 414px) {
  .lightbox-container {
    margin-top: 13%;
  }
}
.lightbox-content {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7);
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7);
}
.lightbox-close {
  text-transform: uppercase;
  background: transparent;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  display: block;
  border: none;
  color: white;
  top: -22px;
  right: 3%;
}
.video-container {
  padding-bottom: 56.25%;
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  height: 0;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

#playme {
  background: white;
  text-transform: uppercase;
  font-weight: 300;
  border: none;
  color: white;
  padding: 5px 15px;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  &:before {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\f01d";
    font-family: FontAwesome;
    font-size: 100px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    @media screen and (max-width:767px){
		font-size: 50px;
    }
  }
  &:hover:before {
    color: #eee;
  }
  img{
  	width: 335px;
  	height: auto;
  	@media screen and (max-width:767px){
  		width: 135px;
  	}
  }
}
.changeu{
	color: white !important;
	background: $maincolor;
	padding: 0px 10px;
	text-transform:capitalize;
    top: 15px;
    right: 20px;
    border-radius: 20px;
    position:absolute;
	&:hover{
		text-decoration: none;
		color: white;
		background:#0D6FCE;
	}
}
#eyepwd {
  position: absolute;
  right: 18px;
  top: 33px;
  background: transparent;
  border: none;
  outline: none;
}

#eyepwd img {
  width: 18px;
}

#eyeconfirm {
  position: absolute;
  right: 18px;
  top:33px;
  background: transparent;
  border: none;
  outline: none;
}

#eyeconfirm img {
  width: 18px;
}

#eyepwdp {
  position: absolute;
  right: 5px;
  top: 7px;
  background: transparent;
  border: none;
  outline: none;
}

#eyepwdp img {
  width: 18px;
}

#eyeconfirmp {
  position: absolute;
  right: 5px;
  top: 7px;
  background: transparent;
  border: none;
  outline: none;
}

#eyeconfirmp img {
  width: 18px;
}

.tbsa {
  zoom: 1;
  position: relative;
  height: auto;
}
.tbsa:after,
.tbsa:before {
  content: "";
  display: table;
}
.tbsa:after {
  clear: both;
}
.tbsa h4 {
  background: rgba(0,0,0,0.1);
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.2);
  border-top: none;
  padding: 15px 20px;
}
.tbsa h4:first-child {
  border-top: 1px solid rgba(0,0,0,0.2);
}
@media screen and (min-width: 600px) {
  .tbsa h4 {
    position: relative;
    width: 33.333333333333336%;
    height: 20%;
    display: block;
  }
}
.tbsa ul {
  zoom: 1;
  position: relative;
  height: auto;
  min-height: 100%;
  border: 1px solid rgba(0,0,0,0.2);
  border-left: none;
  display: none;
}
.tbsa ul:after,
.tbsa ul:before {
  content: "";
  display: table;
}
.tbsa ul:after {
  clear: both;
}
.tbsa ul li {
  list-style: circle;
}
@media screen and (min-width: 600px) {
  .tbsa ul {
    position: absolute;
    width: 66.66666666666667%;
    right: 0;
    top: 0;
    padding: 15px 30px;
  }
}
.tbsa .active {
  cursor: default;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  border-right: none;
}
@media screen and (min-width: 600px) {
  .tbsa .active {
    background: rgba(0,0,0,0);
    border-right: 1px solid rgba(0,0,0,0);
  }
}
.tbsa .active + ul {
  display: block;
}
.scrollToTop{
	width: 100px;
    height: 130px;
    padding: 10px;
    text-align: center;
    background: 0 0;
    font-weight: 700;
    color: #444;
    text-decoration: none;
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
}